.marqueeContainer > .marquee > .initial-child-container > .child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25vw;
}

.marqueeContainer > .marquee > .child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25vw;
}

@media screen and (max-width: 900px) {
    .marqueeContainer > .marquee > .initial-child-container > .child {
        width: 50vw;
    }

    .marqueeContainer-image > .marquee > .child {
        width: 50vw;
    }
}

@media screen and (max-width: 500px) {
    .marqueeContainer > .marquee > .initial-child-container > .child {
        width: 80vw;
    }

    .marqueeContainer-image > .marquee > .child {
        width: 80vw;
    }
}

@media screen and (max-width: 400px) {
    .marqueeContainer > .marquee > .initial-child-container > .child {
        width: 90vw;
    }

    .marqueeContainer-image > .marquee > .child {
        width: 90vw;
    }
}
