.modalContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}
.modal {
  border-radius: 5px;
  /* padding: 1rem; */

  background-color: transparent;
  width: fit-content;
}

.btnSubmit {
  background-color: #42a942;
}

.btnCancel {
  background-color: #d9534f;
}

.close {
  cursor: pointer;
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
}

/* .modalContent {
  margin-bottom: 2rem;
} */

.modalFooter {
  display: flex;
  justify-content: space-evenly;
}
