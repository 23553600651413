.testimonials {
    padding-top: 4em;
    color: black;
    position: relative;
    z-index: 1;
    padding-bottom: 2rem;
    font-family: var(--primary-font-family);
}

.testimonials::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; /* Adjust as needed */
    background-repeat: no-repeat; /* Adjust as needed */
    transform-origin: 0 100%; /* Set the origin of the skew transformation */
    background-color: #1e1e1e;

    z-index: -1;
}
.testimonials h1 {
    font-size: 65px;
    text-align: center;
    margin: 0;
    padding: 0;
    color: white;
    text-transform: uppercase;
}
.testimonials h4 {
    text-align: center;
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
}
.testimonialsDiv {
    display: flex;
    gap: 2em;
    margin-top: 4em;
    justify-content: center;
    align-items: center;
}

.Heading {
    margin-bottom: 3rem;
}

.Client {
    width: 80%;
    padding: 2%;
    background: rgba(255, 255, 255, 0.13);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(1.5px);
    border: 1px solid rgba(255, 255, 255, 0.34);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.InnerClient1,
.InnerClient2,
.InnerClient3 {
    width: 30%;
    height: 420px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.InnerClient1 {
    background-image: url('../../img/testimonials/client331.webp');
}
.InnerClient2 {
    background-image: url('../../img/testimonials/client321.webp');
}
.InnerClient3 {
    background-image: url('../../img/testimonials/client311.webp');
}
.Client img {
    width: 100%;
    border-radius: inherit;
}
.button {
    height: 50%;
    display: flex;
    align-items: center;
    font-size: 50px;
}
.button:hover {
    color: #007bff;
}
.text {
    height: 50%;
    width: 65%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.text h3 {
    margin: 5px;
    /* text-decoration: underline; */
    text-underline-offset: 5px;
}
.text h5 {
    margin: 0;
    font-size: 15px;
    color: #007bff;
    text-decoration: underline;
    text-underline-offset: 5px;
    font-weight: 600;
}
.text p {
    font-size: 14px;
}
.text img {
    width: 11px;
    margin: 0 5px;
}

/* modal */
.modalDiv {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    font-family: var(--primary-font-family);
}
.video {
    width: 600px;
    height: 415px;
}

@media only screen and (max-width: 1280px) {
    .Client {
        width: 90%;
    }
    .Client {
        justify-content: space-between;
    }
}
@media only screen and (max-width: 1000px) {
    /* .modalDiv {
    height: 350px;
  } */
    .modalDiv h2 {
        font-size: 20px;
    }
    .video {
        width: 490px;
        height: 340px;
    }
    .Client {
        width: 90%;
    }
    .testimonials h1 {
        font-size: 55px;
    }
    .text p {
        display: none;
    }
    .button {
        align-items: flex-end;
    }
    .text {
        justify-content: center;
    }
    .text {
        margin-top: 25px;
        justify-content: flex-start;
    }
    .text h3 {
        font-size: 13px;
    }
    .text h5 {
        font-size: 13px;
    }
}
/* @media only screen and (max-width: 850px) {
  .text h3 {
    font-size: 13px;
  }
  .text h5 {
    font-size: 13px;
  }
} */
@media only screen and (max-width: 800px) {
    .testimonials h1 {
        font-size: 50px;
    }
    .modalDiv h2 {
        font-size: 18px;
    }
    .testimonials h4 {
        font-size: 15px;
    }
    .text h3 {
        font-size: 13px;
    }
    .text h5 {
        font-size: 13px;
    }
}
@media only screen and (max-width: 730px) {
    .testimonials h1 {
        font-size: 45px;
    }
    .testimonials h4 {
        font-size: 14px;
    }

    .Client {
        flex-direction: column;
        width: 70%;
        gap: 1em;
    }
    .InnerClient1,
    .InnerClient2,
    .InnerClient3 {
        width: 70%;
        height: 300px;
    }
    .video {
        width: 400px;
        height: 300px;
    }
}
@media only screen and (max-width: 630px) {
    .testimonials h1 {
        font-size: 40px;
    }
    .testimonials h4 {
        font-size: 14px;
    }
    .video {
        width: 380px;
        height: 230px;
    }
}
@media only screen and (max-width: 480px) {
    .testimonials h1 {
        font-size: 35px;
    }
    .testimonials h4 {
        font-size: 10px;
    }
    .InnerClient1,
    .InnerClient2,
    .InnerClient3 {
        width: 80%;
    }
    .text h3 {
        font-size: 13px;
    }
    .text h5 {
        font-size: 13px;
    }
    .video {
        width: 340px;
        height: 270px;
    }
    .modalDiv h2 {
        font-size: 15px;
    }
}
@media only screen and (max-width: 390px) {
    .video {
        width: 280px;
        height: 250px;
    }
}
@media only screen and (max-width: 330px) {
    .video {
        width: 220px;
        height: 180px;
    }
}

.marqueeContainer {
    display: flex;
    margin-right: -10px;
}

.imageContainer1 img {
    width: 180px;
    object-fit: contain;
    height: 60px;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.1);
    border-image: initial;
    margin: 10px;
    border-radius: 10px;
}

.brandsContainer {
    max-width: 1680px;
    margin: 0 auto;
    width: 100%;
}

.brandMarqueeContainer > .marquee > .initial-child-container > .child {
    width: 17vw !important;
}

.testUnder {
    color: #bdee92;
    font-weight: 700;
}

.brandMarqueeContainer > .marquee > .child {
    width: 17vw !important;
}

.newsletterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 1680px;
    margin-top: 65px;
    margin: 125px auto 16px;
}

.newsletter {
    padding: 48px;
    padding-top: 20px;
    border-radius: 28px;
    background: var(--hap-gradient-color);
    width: 80%;
    display: flex;
    flex-direction: column;
    color: white;
}
.newsletter h3 {
    font-size: 36px;
    margin: 0;
    margin-top: 1rem;
}

.newsletter p {
    font-size: 1rem;
    margin-top: 0;
}

.progress-loader {
    top: 50%;
    left: 50%;
    background: gray;
    height: 20px;
    border-radius: 7px;
    margin-bottom: 30px;
}

.progressContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
}

.inputContainer {
    display: flex;
    gap: 1rem;
    height: fit-content;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 0.5rem;
}

.inputContainer input {
    padding: 12px;
    border-radius: 10px;
    font-size: 14px;
    outline: none;
    font-family: var(--primary-font-family);
    border: 2px solid #7fd136;
    width: 240px;
}

.inputContainer button {
    padding: 12px 22px;
    background-color: #7fd136;
    border-radius: 10px;
    text-transform: capitalize;
    font-family: var(--primary-font-family);
}

@media (width< 1200px) {
    .newsletterContainer {
        margin-top: 80px;
    }
}

@media (width<768px) {
    .inputContainer {
        flex-direction: column;
        gap: 1rem;
    }

    .newsletterContainer {
        padding: 0 3rem;
    }

    .newsletter {
        padding: 35px 20px 48px;
        width: 100%;
    }

    .newsletter h3 {
        font-size: 24px;
        margin-bottom: 0.2rem;
    }

    .progressContainer > div {
        width: 80% !important;
    }
    .testimonials {
        padding-top: 1rem;
    }
}
