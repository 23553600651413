.Contactus {
    display: flex;
    position: relative;
    z-index: 0;
    margin-top: 6em;
    width: 100%;
    justify-content: space-around;
    font-family: var(--primary-font-family);
}

.contactContainer {
    display: flex;
    width: 100%;
    justify-content: space-around;
    max-width: 1680px;
    margin: 0 auto;
}
.Contactus::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: var(--footer-bg); */
    background-image: url('../../img/Home/Component44.webp');
    background-repeat: no-repeat;
    background-size: cover;
    transform-origin: 0 100%; /* Set the origin of the skew transformation */
    z-index: -1;
}
.Contactus .FormSection {
    width: 40%;
    padding-top: 5em;
    padding-left: 2em;
    padding-bottom: 7em;
}

.Contactus form {
    margin-top: 2em;
}
/* grid */
.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    /* grid-template-rows: repeat(3, 1fr); */
    gap: 20px;
}
.gridItem {
    /* border: 1px solid white; */
    width: 100%;
}
.span2 {
    grid-column: span 2;
}
.gridItem input {
    padding: 7% 7.5%;
    border-radius: 50px;
    width: 85%;
    font-size: 18px;
    outline: none;
    font-family: var(--primary-font-family);
}
.gridItem textarea {
    border-radius: 30px;
    padding: 5%;
    outline: none;
    width: 90%;
    max-width: 90%;
    min-width: 90%;
    max-height: 200px;
    min-height: 100px;
    font-size: 18px;
    font-family: var(--primary-font-family);
}

.Contactus .ImageSection {
    width: 45%;
    margin-top: -2.65%;
    margin-bottom: 2.6%;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
    /* background-image: url("../../img/Home/contact-bg.jpg"); */
    /* background-size: cover; */
    /* transform: skewY(-2deg); */
}
.ImageSection h4 {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.Contactus .ImageSection h1 {
    font-size: 65px;
    text-align: left;
    margin: 0;
    padding: 0;
    color: white;
    text-transform: uppercase;
}
.btn {
    flex: 1 1 auto;
    margin-bottom: 10px;
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    transition: 0.5s;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    border-radius: 20px;
    border: none;
    width: 100%;
    text-transform: uppercase;
}
.btn:hover {
    --x: 100%;
    cursor: pointer;
}
#btn1 {
    --c1: #c600ff;
    --c2: #007bff;
    color: white;
}
@media only screen and (max-width: 1100px) {
    .Contactus .ImageSection h1 {
        font-size: 60px;
    }
}
@media only screen and (max-width: 1000px) {
    .Contactus .ImageSection h1 {
        font-size: 55px;
    }
    .ImageSection h4 {
        font-size: 15px;
    }
    .gridItem input,
    .gridItem textarea {
        font-size: 15px;
    }
}
@media only screen and (max-width: 850px) {
    .Contactus {
        flex-direction: column-reverse;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: fit-content;
    }

    .Contactus .ImageSection {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Contactus .ImageSection h1 {
        font-size: 50px;
        text-align: center;
    }
    .ImageSection h4 {
        font-size: 15px;
    }
    .ImageSection p {
        font-size: 15px;
        text-align: center;
    }
    .gridItem input,
    .gridItem textarea {
        font-size: 15px;
    }
}
@media only screen and (max-width: 730px) {
    .Contactus .ImageSection {
        width: 70%;
    }
    .Contactus .ImageSection h1 {
        font-size: 50px;
    }
}
@media only screen and (max-width: 650px) {
    .Contactus .ImageSection h1 {
        font-size: 45px;
    }
    .btn {
        font-size: 15px;
    }
}
@media only screen and (max-width: 550px) {
    .Contactus .ImageSection h1 {
        font-size: 45px;
    }
    .Contactus .ImageSection h4 {
        font-size: 13px;
        text-align: center;
    }
    .ImageSection p {
        font-size: 13px;
    }
    .Contactus .FormSection {
        width: 80%;
    }
}

@media only screen and (max-width: 450px) {
    .Contactus .ImageSection h1 {
        font-size: 35px;
    }
    .ImageSection {
        width: 80%;
    }
    .btn {
        font-size: 14px;
        padding: 13px 20px;
    }
}
@media only screen and (max-width: 300px) {
    .gridItem input,
    .gridItem textarea {
        font-size: 10px;
    }
    .btn {
        font-size: 12px;
        padding: 12px 20px;
    }
}

@media (width< 1200px) {
    .Contactus {
        margin-top: 1em;
    }

    .Contactus .FormSection {
        padding-left: 0;
        padding-top: 3em;
        padding-bottom: 5em;
    }
}

@media (width<992px) {
    .contactContainer {
        flex-direction: column-reverse;
        align-items: center;
    }

    .Contactus .FormSection {
        width: 80%;
        padding-top: 0;
        padding-bottom: 1rem;
    }

    .Contactus {
        padding: 3em 0;
    }
}

@media (width< 768px) {
    .Contactus .FormSection {
        width: 90%;
    }
}
