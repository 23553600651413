.description {
  font-family: var(--primary-font-family);
  text-align: center;
}

.description h4 {
  background: var(--hap-gradient-color-reverse);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
