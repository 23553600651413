a,
a:hover,
a:focus {
  text-decoration: none;
  transition: 0.5s;
  outline: none;
}

.text-primary {
  color: #5a8dee !important;
}
.card {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
  background-color: #fff;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: black;
  overflow: hidden;
}
.rcAccordionToggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  background-color: #ebedf0;
  transition: 0.3s;
  padding: 0 20px;
}
.rcAccordionToggle.active {
  background-color: #5a8dee;
}
.rcAccordionToggle.active .rcAccordionIcon {
  transform: rotate(180deg);
  color: #fff;
}
.rcAccordionCard {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  font-family: var(--primary-font-family);
  font-size: 18px;
}
.rcAccordionCard:last-child {
  margin-bottom: 0;
}
.rcAccordionTitle {
  font-weight: 500;
  font-size: 14px;
  position: relative;
  /* margin-bottom: 0; */
  color: black;
  transition: 0.3s;
}
.rcAccordionToggle.active .rcAccordionTitle {
  color: #fff;
}
.rcAccordionIcon {
  position: relative;
  top: 2px;
  color: #475f7b;
  transition: 0.35s;
  font-size: 12px;
}
.rcAccordionBody {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 15px;
  background-color: whitesmoke;
}
.rcAccordionBody p {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: black;
}
.rcCollapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.rcCollapse.show {
  height: auto;
}
@media only screen and (max-width: 1000px) {
  .rcAccordionToggle h5 {
    font-size: 15px;
  }
  .rcAccordionBody p {
    font-size: 15px;
  }
  .rcAccordionBody {
    font-size: 15px;
  }
}
@media only screen and (max-width: 500px) {
  .rcAccordionToggle h5 {
    font-size: 13px;
  }
  .rcAccordionBody p {
    font-size: 13px;
  }
}
