.AccordionFAQ {
    background-image: url(../../img/Background/test-bg.webp);
    background-attachment: fixed;

    font-family: var(--primary-font-family);
    padding-top: 3em;
    color: black;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1680px;
    margin: 0 auto;
    width: 100%;
}
.AccordionFAQ h1 {
    font-size: 65px;
    margin: 0;
}
.AccordionFAQ h4 {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 10px 0;
}
.glass {
    width: 80%;
    margin: 4em 0 8em 0;
}
@media only screen and (max-width: 1200px) {
    .AccordionFAQ h1 {
        font-size: 55px;
    }
    .AccordionFAQ h4 {
        font-size: 15px;
    }
}
@media only screen and (max-width: 1000px) {
    .AccordionFAQ h1 {
        font-size: 45px;
    }
    .AccordionFAQ h4 {
        font-size: 15px;
    }
}
@media only screen and (max-width: 600px) {
    .AccordionFAQ h1 {
        font-size: 40px;
    }
    .AccordionFAQ h4 {
        font-size: 13px;
    }

    .glass {
        width: 90%;
        margin-bottom: 4em;
    }
}
@media only screen and (max-width: 450px) {
    .AccordionFAQ h1 {
        font-size: 35px;
    }
    .AccordionFAQ h4 {
        font-size: 13px;
    }
}
@media only screen and (max-width: 281px) {
    .AccordionFAQ h1 {
        font-size: 28px;
    }
    .AccordionFAQ h4 {
        font-size: 13px;
    }
}
