.StepOuterDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--primary-font-family);
}
.Step1OuterDiv {
    width: 50%;
}
.Step1OuterDiv h1 {
    font-size: 50px;
    margin: 0;
    font-weight: bolder;
}

.Step1OuterDiv p,
.Step4OuterDiv p {
    font-size: 20px;
    margin: 1em 0;
}
.Step1OuterDiv .wiggleImage {
    width: 50px;
    animation: wiggle 1s ease-in-out infinite; /* Adjust the duration and timing function as needed */
    animation-delay: 5s;
}
@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(10deg);
    }
    50% {
        transform: rotate(-10deg);
    }
    75% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

/* Step Two */
.Step2OuterDiv {
    width: 60%;
}
.Step2OuterDiv h1 {
    font-size: 40px;
    margin: 0;
    font-weight: bolder;
}
.Step2OuterDiv input,
.Step3OuterDiv input,
.Step8OuterDiv input {
    padding: 20px;
    margin-top: 1em;
    border-radius: 5px;
    font-size: 20px;
    width: inherit;
}

/* Step 3*/
.Step3OuterDiv {
    width: 90%;
}
.Step3OuterDiv h1 {
    font-size: 50px;
}
/* Step 4 */
.Step4OuterDiv {
    width: 60%;
}
.Step4OuterDiv p {
    font-size: 25px;
    margin: 5px;
}
.Step4OuterDiv h1 {
    font-size: 45px;
    margin: 5px;
}
.Step4OuterDiv .container {
    transition: all 1s ease;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
}
.Step4OuterDiv .wrapper .container img {
    width: 100px;
}
.Step4OuterDiv .wrapper .container input[type='checkbox']::after {
    position: absolute;
    font-family: 'Font Awesome 5 free';
    font-weight: 400;
    content: '\f111';
    font-size: 18px;
    color: #007bff;
    right: -7px;
    top: -20px;
}
.Step4OuterDiv .wrapper .container input[type='checkbox']:checked:after {
    font-weight: 900;
    content: '\f058';
    color: #007bff;
}
.Step4OuterDiv .container:hover {
    transform: translateY(-20px);
    transition: all 1s ease;
}

/* Step5 */
.Step5OuterDiv {
    width: 80%;
}
.Step5OuterDiv h1 {
    font-size: 50px;
    margin-bottom: 0;
}
.Step5OuterDiv p {
    margin: 15px;
    color: grey;
}
.Step5OuterDiv .container {
    /* height: 150px; */
    /* height: fit-content; */
    width: 20%;
    background-color: white;
    border-radius: 8px;
}
.Step5OuterDiv input[type='checkbox'] {
    -webkit-appearance: none;
    position: relative;
    width: 90%;
    cursor: pointer;
}

.Step5OuterDiv input[type='checkbox']::after {
    position: absolute;
    font-family: 'Font Awesome 5 free';
    font-weight: 400;
    content: '\f111';
    font-size: 18px;
    color: #007bff;
    right: 10px;
    top: -5px;
}
input[type='checkbox']:checked:after {
    font-weight: 900;
    content: '\f058';
    color: #007bff;
}
.Step5OuterDiv .container label {
    width: inherit;
    height: inherit;
}
.Step5OuterDiv .container label img {
    width: 50%;
}
.wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 80%;
    margin-top: 2em;
}

/* Step6 */
.Step6OuterDiv {
    width: 80%;
}
.Step6OuterDiv h1 {
    font-size: 50px;
    margin: 0;
}

.Step6OuterDiv select {
    padding: 20px;
    margin-top: 0.25em;
    border-radius: 5px;
    font-size: 20px;
}
.optionsDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
}
/* .optionsDiv div label {
  width: 100%;
} */
.optionsDiv div {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    background-color: white;
    font-size: 22px;
}
.optionsDiv div label {
    /* background-color: red; */
    width: 100%;
    padding: 15px 10px 15px 20px;
    text-align: left;
}
.optionsDiv div input {
    margin: 0;
    padding: 10px 15px 10px 15px;
}

input[type='radio'] {
    -webkit-appearance: none;
    position: relative;
    width: 100%;
    cursor: pointer;
    display: inline;
}

input[type='radio']::after {
    /* position: absolute; */
    font-family: 'Font Awesome 5 free';
    font-weight: 400;
    content: '\f111';
    font-size: 20px;
    color: #007bff;
    right: 10px;
    top: 5px;
}
input[type='radio']:checked:after {
    font-weight: 900;
    content: '\f058';
    color: #007bff;
}
/* Step8 */
.Step8OuterDiv {
    width: 90%;
}
.Step8OuterDiv h1 {
    font-size: 50px;
    margin: 0;
}
.Step8OuterDiv p {
    font-size: 20px;
}

/* Step 9 */
.Step9OuterDiv {
    width: 70%;
}
.Step9OuterDiv h1 {
    font-size: 45px;
    margin: 0.5em 0;
}
.Step9OuterDiv textarea {
    border-radius: 10px;
    width: 80%;
    padding: 15px 10px 15px 20px;
    font-size: 20px;
}

/* Step 10 */
.Step10OuterDiv {
    width: 100%;
    position: relative;
}
.Step10OuterDiv h1 {
    font-size: 50px;
    margin: 0.5em 0;
}
.Step10OuterDiv p {
    margin: 0;
    font-size: 20px;
}
.Step10OuterDiv h2 {
    background-color: white;
    padding: 5px 10px;
    border-radius: 20px;
}
.Step10OuterDiv input {
    border-radius: 10px;
    width: 80%;
    padding: 15px 10px 15px 20px;
    font-size: 20px;
}
.DragHere {
    position: absolute;
    left: -5%;
    bottom: -20%;
}
.DragHere img {
    width: 150px;
}
.slider {
    position: relative;
}
.slider input[type='range'] {
    width: 80%;
    transition: transform 0.5s ease; /* Use your desired animation duration and easing function */
}
/* Step 11 */
.Step11OuterDiv {
    width: 70%;
}
.Step11OuterDiv h1 {
    font-size: 40px;
    margin: 0.25em 0;
}
.Step11OuterDiv p {
    font-size: 20px;
    margin: 0.5em 0 1em 0;
}
.Step11OuterDiv textarea {
    border-radius: 10px;
    width: 80%;
    padding: 15px 10px 15px 20px;
    font-size: 20px;
}
/* .Step13 */
.Step13OuterDiv {
    width: 80%;
}
.Step13OuterDiv h1 {
    font-size: 45px;
}
/* Step14 */
.Step14OuterDiv {
    width: 80%;
}
.Step14OuterDiv h1 {
    font-size: 45px;
    margin: 0 0 20px 0;
}
.Step14OuterDiv p {
    font-size: 20px;
    margin: 0;
}
.optionsDiv14 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
}
/* .optionsDiv div label {
  width: 100%;
} */
.optionsDiv14 div {
    width: 8%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    padding: 15px 20px 15px 20px;
    background-color: white;
    font-size: 18px;
}
.optionsDiv14 div label {
    width: 100%;
    text-align: left;
}
.optionsDiv14 div input {
    margin: 0;
    padding: 10px 15px 10px 15px;
}

input[type='radio'] {
    -webkit-appearance: none;
    position: relative;
    width: 100%;
    cursor: pointer;
    display: inline;
}

input[type='radio']::after {
    /* position: absolute; */
    font-family: 'Font Awesome 5 free';
    font-weight: 400;
    content: '\f111';
    font-size: 15px;
    color: #007bff;
    right: 10px;
    top: 5px;
}
input[type='radio']:checked:after {
    font-weight: 900;
    content: '\f058';
    color: #007bff;
}

/* Step15 */
.Step15OuterDiv {
    width: 80%;
}
.Step15OuterDiv h1 {
    font-size: 50px;
    margin: 0;
}
.Step15OuterDiv p {
    font-size: 20px;
}
.Step15OuterDiv select {
    padding: 20px;
    margin-top: 0.25em;
    border-radius: 5px;
    font-size: 20px;
}
/* Step17 */
.Step17OuterDiv {
    width: 70%;
}
.Step17OuterDiv h1 {
    font-size: 40px;
    margin: 0;
}
.Step17OuterDiv p {
    font-size: 20px;
    margin: 0.5em 0;
}
/* .Step17OuterDiv div {
  width: 80%;
} */
.Step17OuterDiv input {
    border-radius: 5px;
    /* width: 100%; */
    margin: 0.25em 0;
    padding: 15px 10px 15px 20px;
    font-size: 20px;
}

/* Button */
.finalButton {
    opacity: 50%;
}
.ButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
}
.btn {
    width: fit-content;
    flex: 1 1 auto;
    margin: 30px 10px 20px 10px;
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    border: none;
    display: inline-block;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    font-size: 18px;
    font-weight: 600;
}
.btn div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.btn div svg {
    font-size: 25px;
}
.btn:hover {
    --x: 100%;
    cursor: pointer;
}
#btn1 {
    --c1: #c600ff;
    --c2: #007bff;
    color: white;
}
button:disabled {
    opacity: 0.5; /* Example: Reducing opacity for a disabled look */
    cursor: not-allowed; /* Example: Changing cursor to indicate it's not clickable */
}
.breakword {
    word-break: break-all;
}
.icons {
    font-size: 80px;
}

/*  */

.skil-main {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.skill-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.skill-name {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
}

.skillBar {
    height: 20px;
    background-color: #00283a;
    border-radius: 8px;
}

.skill-per {
    height: 20px;
    background: #23576f;
    border-radius: 8px;
    width: 0;
    transition: 1s linear;
    position: relative;
}
.skill-per:before {
    content: attr(per);
    position: absolute;
    padding: 4px 6px;
    background-color: #23576f;
    color: #fff;
    font-size: 11px;
    border-radius: 4px;
    top: -35px;
    right: 0;
    transform: translateX(50%);
}

.skill-per:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #23576f;
    top: -20px;
    right: 0;
    transform: translateX(50%) rotate(45deg);
    border-radius: 2px;
}
/* Step 18 */
.skillMainStyle {
    width: 100%;
    background-color: #4498f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 100vh;
    font-family: var(--primary-font-family);
}
.skillWrapStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 70%;
}
.skillWrapStyle h4 {
    font-size: 30px;
    margin: 0;
    color: #eaff3c;
    margin-top: -40px;
    z-index: 2;
}
.skillWrapStyle .text {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text svg {
    font-size: 30px;
    margin-right: 10px;
    color: #eaff3c;
}
/* .skillNameStyle {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
} */

.skillBarStyle {
    height: 15px;
    background-color: #00283a;
    border-radius: 8px;
    width: 30%;
}

.skillPerStyle {
    height: 20px;
    background: #ffffff;
    border-radius: 8px;
    width: 0;
    transition: 1s linear;
    position: relative;
}

.skillPerBeforeStyle {
    content: attr(per);
    position: absolute;
    padding: 4px 6px;
    background-color: #23576f;
    color: #fff;
    font-size: 11px;
    border-radius: 4px;
    top: -35px;
    right: 0;
    transform: translateX(50%);
}

.skillPerAfterStyle {
    content: '""';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #23576f;
    top: -20px;
    right: 0;
    transform: translateX(50%) rotate(45deg);
    border-radius: 2px;
}
.VideoDiv18 {
    width: 100%;
    position: relative;
    height: 280px;
    display: flex;
    margin-top: -30px;
    justify-content: center;
}
.video18 {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden;
}
.stars {
    display: flex;
    gap: 8px;
}
.stars img {
    height: 20px;
    object-fit: contain;
}
/* Step 19 */
.topBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.eyebrow__flashing {
    color: #fff !important;
    text-transform: uppercase;
    font-size: 1.3rem;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50px;
    padding: 5px 17px 4px;
    letter-spacing: 0.05rem;
    margin: 0 auto 2rem;
    display: table;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    font-size: 1.8rem;
    padding: 7px 30px 4px 20px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    display: inline-flex;

    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.eyebrow__flashing_dot {
    background-color: red;
    content: ' ';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 20px;
    margin-left: -3px;
    top: -1px;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
}
.eyebrow__flashing_dot:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border-radius: 50%;
    background: #f74424;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 1;
    -webkit-animation: smallPulse 2s ease-out infinite;
    animation: smallPulse 2s ease-out infinite;
}
@keyframes smallPulse {
    0% {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
        opacity: 1;
    }
    95%,
    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0;
    }
}
.eyebrow__flashing h2 {
    font-size: 18px;
    margin: 0;
    font-weight: normal;
}
.topBar h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -0.08em;
    display: block;
    margin: 0;
    font-size: 70px;
}
.topBar p {
    font-size: 1.5em;
    color: rgb(82, 82, 82);
}
.VideoDiv {
    width: 100%;
    height: 0px;
    position: relative;
    padding-bottom: 56.25%;
    display: flex;
    justify-content: center;
}
.video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden;
}
@media only screen and (max-width: 1290px) {
    .Step5OuterDiv p {
        font-size: 15px;
    }
    .Step8OuterDiv h1,
    .Step9OuterDiv h1,
    .Step10OuterDiv h1 {
        font-size: 40px;
    }
    .Step17OuterDiv h1 {
        font-size: 35px;
    }
    .Step17OuterDiv p {
        font-size: 17px;
    }
    .Step19OuterDiv h1 {
        font-size: 65px;
    }
    .Step19OuterDiv p {
        font-size: 1.3em;
    }
    .DragHere {
        position: absolute;
        left: -11%;
        bottom: -20%;
    }
    .DragHere img {
        width: 150px;
    }
}
@media only screen and (max-width: 1200px) {
    .Step1OuterDiv,
    .Step2OuterDiv,
    .Step3OuterDiv,
    .Step4OuterDiv,
    .Step6OuterDiv,
    .Step13OuterDiv {
        width: 60%;
    }
    .Step3OuterDiv {
        width: 70%;
    }
    .Step4OuterDiv p {
        font-size: 20px;
    }
    .Step4OuterDiv h1,
    .Step5OuterDiv h1,
    .Step6OuterDiv h1,
    .Step11OuterDiv h1,
    .Step14OuterDiv h1 {
        font-size: 40px;
    }
    .Step14OuterDiv label {
        font-size: 15px;
    }
    .optionsDiv14 div {
        padding: 10px;
    }
    .icons {
        font-size: 70px;
    }
    .DragHere {
        position: absolute;
        left: -11%;
        bottom: -22%;
    }
    .DragHere img {
        width: 140px;
    }
}
@media only screen and (max-width: 1000px) {
    .Step1OuterDiv,
    .Step2OuterDiv,
    .Step3OuterDiv,
    .Step4OuterDiv,
    .Step13OuterDiv {
        width: 70%;
    }
    .Step6OuterDiv {
        width: 60%;
    }
    .Step1OuterDiv h1,
    .Step2OuterDiv h1,
    .Step5OuterDiv h1 {
        font-size: 40px;
    }
    .Step11OuterDiv p {
        font-size: 15px;
    }
    .Step4OuterDiv h1,
    .Step2OuterDiv h1,
    .Step11OuterDiv h1,
    .Step14OuterDiv h1 {
        font-size: 35px;
    }
    .Step5OuterDiv .container label img {
        width: 45%;
    }
    .Step6OuterDiv select {
        width: 100%;
    }
    .Step3OuterDiv .optionsDiv div,
    .Step13OuterDiv .optionsDiv div {
        width: 70%;
    }
    .optionsDiv14 div {
        width: 10%;
        padding: 10px;
    }
    .optionsDiv14 input[type='radio']::after {
        font-size: 10px;
        color: #007bff;
        left: 1px;
        top: 5px;
    }
    .btn {
        font-size: 15px;
        padding: 13px 23px;
    }
}
@media only screen and (max-width: 890px) {
    .Step1OuterDiv {
        width: 70%;
    }
    .Step2OuterDiv {
        width: 90%;
    }
    .Step3OuterDiv,
    .Step4OuterDiv,
    .Step13OuterDiv {
        width: 80%;
    }
    .Step1OuterDiv h1 {
        font-size: 45px;
    }
    .Step4OuterDiv h1 {
        font-size: 40px;
    }
    .Step2OuterDiv h1,
    .Step9OuterDiv h1,
    .Step10OuterDiv h1 {
        font-size: 35px;
    }
    .Step11OuterDiv h1,
    .Step14OuterDiv h1,
    .Step4OuterDiv h1 {
        font-size: 30px;
    }
    .Step9OuterDiv textarea,
    .Step11OuterDiv textarea {
        font-size: 17px;
    }
    .Step1OuterDiv p,
    .Step10OuterDiv p,
    .Step4OuterDiv p {
        font-size: 20px;
    }
    .Step5OuterDiv .wrapper {
        width: 100%;
    }
    .Step6OuterDiv select {
        width: 90%;
        font-size: 15px;
    }
    .Step3OuterDiv .optionsDiv div,
    .Step13OuterDiv .optionsDiv div {
        width: 80%;
    }
    .Step3OuterDiv label,
    .Step13OuterDiv label {
        font-size: 18px;
    }
    .Step3input {
        width: 80%;
    }
    .Step3OuterDiv .Step3h1 {
        font-size: 35px;
    }
    .optionsDiv14 div {
        width: 15%;
    }
    .icons {
        font-size: 60px;
    }
    .Step17OuterDiv h1 {
        font-size: 30px;
    }
    .Step17OuterDiv p {
        font-size: 15px;
    }
    .Step17OuterDiv input {
        font-size: 15px;
    }
    .Step19OuterDiv h2 {
        font-size: 17px;
    }
    .Step19OuterDiv h1 {
        font-size: 55px;
    }
    .Step19OuterDiv p {
        font-size: 1.1em;
    }
    .Step10outerdiv input[type='range'] {
        width: 50%;
    }
    .DragHere {
        position: absolute;
        left: 0%;
        bottom: -13%;
    }
    .DragHere img {
        width: 90px;
    }
}
@media only screen and (max-width: 650px) {
    .Step1OuterDiv,
    .Step3OuterDiv,
    .Step6OuterDiv,
    .Step9OuterDiv,
    .Step11OuterDiv,
    .Step13OuterDiv {
        width: 80%;
    }
    .Step5OuterDiv {
        width: 100%;
    }
    .Step1OuterDiv h1,
    .Step3OuterDiv h1,
    .Step13OuterDiv h1 {
        font-size: 40px;
    }
    .Step4OuterDiv {
        width: 90%;
    }
    .Step2OuterDiv h1 {
        font-size: 35px;
    }
    .Step6OuterDiv h1 {
        font-size: 34px;
    }
    .Step1OuterDiv p {
        font-size: 17px;
    }
    .Step4OuterDiv h1 {
        font-size: 38px;
    }
    .Step5OuterDiv h1,
    .Step4OuterDiv h1 {
        font-size: 30px;
    }
    .Step11OuterDiv h1,
    .Step14OuterDiv h1 {
        font-size: 25px;
    }
    .Step5OuterDiv p,
    .Step11OuterDiv p,
    .Step14OuterDiv p {
        font-size: 13px;
    }
    .Step3OuterDiv .Step3h1 {
        font-size: 30px;
    }
    .Step8OuterDiv h1 {
        font-size: 30px;
    }
    .Step8OuterDiv input {
        font-size: 20px;
    }
    .Step17OuterDiv h1 {
        font-size: 25px;
    }
    .Step17OuterDiv p {
        font-size: 13px;
    }
    .Step17OuterDiv input {
        font-size: 12px;
        padding: 10px;
    }
}
@media only screen and (max-width: 550px) {
    .Step1OuterDiv,
    .Step3OuterDiv,
    .Step6OuterDiv,
    .Step13OuterDiv {
        width: 80%;
    }
    .Step2OuterDiv,
    .Step9OuterDiv,
    .Step11OuterDiv,
    .Step17OuterDiv {
        width: 95%;
    }
    .Step4OuterDiv {
        width: 100%;
    }
    .Step2OuterDiv h1,
    .Step9OuterDiv h1 {
        font-size: 30px;
    }
    .Step4OuterDiv h1 {
        font-size: 35px;
    }
    .Step4OuterDiv h1 {
        font-size: 30px;
    }
    .Step4OuterDiv p {
        font-size: 20px;
    }
    .Step5OuterDiv p,
    .Step10OuterDiv p {
        font-size: 12px;
    }
    .Step3OuterDiv label,
    .Step13OuterDiv label {
        font-size: 15px;
    }
    .Step3OuterDiv .Step3h1 {
        font-size: 25px;
    }
    .optionsDiv14 div {
        width: 18%;
        font-size: 10px;
    }
    .icons {
        font-size: 50px;
    }
    .Step19OuterDiv h2 {
        font-size: 15px;
    }
    .Step19OuterDiv h1 {
        font-size: 45px;
    }
    .Step19OuterDiv p {
        font-size: 1em;
    }
}
@media only screen and (max-width: 450px) {
    .Step1OuterDiv,
    .Step14OuterDiv {
        width: 90%;
    }
    .Step1OuterDiv h1 {
        font-size: 38px;
    }
    .Step2OuterDiv h1 {
        font-size: 28px;
    }
    .Step3OuterDiv h1,
    .Step5OuterDiv h1,
    .Step6OuterDiv h1,
    .Step10OuterDiv h1,
    .Step13OuterDiv h1 {
        font-size: 30px;
    }
    .Step10OuterDiv h2,
    .Step14OuterDiv h1 {
        font-size: 20px;
    }
    .Step1OuterDiv p {
        font-size: 15px;
    }
    .Step4OuterDiv .wrapper .container img {
        width: 80px;
    }
    .Step4OuterDiv h1 {
        font-size: 27px;
    }
    .Step4OuterDiv p {
        font-size: 18px;
    }
    .Step5OuterDiv input[type='checkbox']::after {
        position: absolute;
        font-family: 'Font Awesome 5 free';
        font-weight: 400;
        content: '\f111';
        font-size: 10px;
        color: #007bff;
        right: 10px;
        top: -5px;
    }
    .Step5OuterDiv input[type='checkbox']:checked:after {
        font-weight: 900;
        content: '\f058';
        color: #007bff;
    }
    .Step5OuterDiv .wrapper .container {
        width: 30%;
    }

    .Step8OuterDiv input {
        font-size: 15px;
    }
    .optionsDiv14 div {
        width: 20%;
    }
    .optionsDiv14 div label {
        font-size: 13px;
    }
    .Step19OuterDiv h2 {
        font-size: 13px;
    }
    .Step19OuterDiv h1 {
        font-size: 40px;
    }
    .Step19OuterDiv p {
        font-size: 0.75em;
    }
    .DragHere {
        position: absolute;
        left: -3%;
        bottom: -8%;
    }
    .DragHere img {
        width: 60px;
    }
}
@media only screen and (max-width: 400px) {
    .Step4OuterDiv .wrapper .container img {
        width: 60px;
    }
    .Step4OuterDiv .wrapper {
        gap: 5px;
        width: 10%;
        flex-wrap: nowrap;
    }
    .Step3OuterDiv,
    .Step13OuterDiv {
        width: 80%;
    }
    .Step2OuterDiv h1,
    .Step9OuterDiv h1,
    .Step11OuterDiv h1 {
        font-size: 25px;
    }
    .Step9OuterDiv textarea {
        font-size: 15px;
    }
    .Step2OuterDiv input {
        font-size: 15px;
    }
    .Step5OuterDiv p {
        font-size: 10px;
        padding: 10px 5px 10px 5px;
    }
    .Step8OuterDiv h1 {
        font-size: 25px;
    }
    .Step8OuterDiv p {
        font-size: 15px;
    }
    .optionsDiv14 div {
        width: 25%;
    }
    .Step17OuterDiv h1 {
        font-size: 20px;
    }
    .Step17OuterDiv p {
        font-size: 12px;
    }
}
@media only screen and (max-width: 350px) {
    .Step1OuterDiv {
        width: 95%;
    }
    .Step3OuterDiv,
    .Step13OuterDiv {
        width: 90%;
    }

    .Step1OuterDiv h1,
    .Step3OuterDiv h1,
    .Step13OuterDiv h1 {
        font-size: 32px;
    }
    .Step2OuterDiv h1,
    .Step10OuterDiv h1 {
        font-size: 25px;
    }
    .Step1OuterDiv p {
        font-size: 13px;
    }
    .Step4OuterDiv .wrapper .container img {
        width: 50px;
    }
    .Step5OuterDiv .wrapper .container {
        width: 40%;
    }
    .Step3OuterDiv .Step3h1 {
        font-size: 20px;
    }
    .Step3OuterDiv input,
    .Step13OuterDiv input .Step10OuterDiv p {
        font-size: 10px;
    }
    .Step10OuterDiv h2 {
        font-size: 18px;
    }
    .Step19OuterDiv h2 {
        font-size: 10px;
    }
    .Step19OuterDiv h1 {
        font-size: 30px;
    }
    .Step19OuterDiv p {
        font-size: 0.75em;
    }
    .btn {
        font-size: 15px;
        padding: 10px 20px;
    }
}
@media only screen and (max-width: 281px) {
    .Step1OuterDiv,
    .Step13OuterDiv {
        width: 100%;
    }
    .Step2OuterDiv {
        width: 90%;
    }
    .Step3OuterDiv {
        width: 70%;
    }
    .Step2OuterDiv h1,
    .Step5OuterDiv h1 {
        font-size: 25px;
    }
    .Step1OuterDiv h1 {
        font-size: 28px;
    }
    .Step3OuterDiv h1,
    .Step13OuterDiv h1,
    .Step4OuterDiv h1 {
        font-size: 30px;
    }
    .Step3OuterDiv input {
        padding: 10px;
    }
    .Step4OuterDiv h1 {
        font-size: 20px;
    }
    .Step1OuterDiv p,
    .Step4OuterDiv p,
    .Step8OuterDiv p {
        font-size: 13px;
    }
    .Step3OuterDiv .Step3h1 {
        font-size: 15px;
    }
    .Step3OuterDiv input,
    .Step8OuterDiv input {
        font-size: 10px;
    }
    .Step13OuterDiv input {
        width: 100%;
    }
    .Step8OuterDiv h1,
    .Step9OuterDiv h1,
    .Step14OuterDiv h1 {
        font-size: 20px;
    }
    .optionsDiv14 div {
        width: 40%;
    }
    .icons {
        font-size: 40px;
    }
}
