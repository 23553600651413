.topBanner {
    height: 55vh;
    /* background-image: url("../../img/Background/Component\ 18.png"); */
    background-attachment: fixed;
    background-size: cover;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0;
    font-family: var(--primary-font-family);
}
.topBanner h1 {
    margin: 0;
    text-align: center;
    margin-top: 2em;
    font-size: 80px;
}
.BannerSubHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 7px 15px;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 1.5em;
}
.BannerSubHeading h3 {
    margin: 0;
    font-size: 18px;
}
@media only screen and (max-width: 1200px) {
    .topBanner h1 {
        font-size: 70px;
    }
    .topBanner {
        height: 80vh;
    }
}
@media only screen and (max-width: 850px) {
    .topBanner h1 {
        font-size: 60px;
    }
    .BannerSubHeading h3 {
        font-size: 15px;
    }
    .topBanner {
        height: 70vh;
    }
}
@media only screen and (max-width: 550px) {
    .topBanner h1 {
        font-size: 50px;
    }
    .BannerSubHeading h3 {
        font-size: 13px;
    }
    .BannerSubHeading {
        padding: 5px 10px;
    }
    .topBanner {
        height: 60vh;
    }
}
@media only screen and (max-width: 400px) {
    .topBanner h1 {
        font-size: 40px;
    }
    .BannerSubHeading h3 {
        font-size: 13px;
    }
    .BannerSubHeading {
        padding: 5px 10px;
    }
    .topBanner {
        height: 50vh;
    }
}
