.Body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(246, 244, 247);
  height: fit-content;
  overflow: hidden;
}
.Content {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  /* width: 100%; */
}
