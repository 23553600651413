.TopHeader {
  font-family: var(--primary-font-family);
  color: var(--hap-base-color);
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 15px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.354);
}
.TopHeader h1 {
  margin: 7px;
  display: inline-block;
  flex: 1;
  text-align: center;
  margin-right: 40px;
}
.TopHeader button {
}

.ButtonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  border: none;
  display: inline-block;
  color: #c600ff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  font-size: 15px;
  font-weight: 600;
}
/* .finalButton {
  color: grey;
} */
.btn div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.btn div svg {
  font-size: 25px;
}
.btn:hover {
  color: #007bff;
}
.finalButton {
  /* opacity: 0; */
  display: none;
}
@media only screen and (max-width: 880px) {
  .TopHeader h1 {
    font-size: 25px;
    margin-right: 7px;
  }
}
@media only screen and (max-width: 570px) {
  .TopHeader h1 {
    font-size: 23px;
    margin-right: 7px;
  }
}
@media only screen and (max-width: 281px) {
  .TopHeader h1 {
    font-size: 20px;
    margin-right: 7px;
  }
}
