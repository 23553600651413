@import url('../../variable.css');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@font-face {
    font-family: 'Neue';
    src: url(../../font/NHaasGroteskDSPro-55Rg.woff);
}
@font-face {
    font-family: 'NeueBold';
    src: url(../../font/NHaasGroteskTXPro-75Bd.woff);
}

.strategy__section {
    font-family: Neue;
    color: #0e0e0e;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.spline-container {
    font-family: 'Space Grotesk', Sans-serif;
    background: #0f0c29; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #24243e,
        #302b63,
        #0f0c29
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #24243e,
        #302b63,
        #0f0c29
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    position: relative;
}

span {
    margin: 0;
}

.strategy__globe {
    position: relative;
    height: 100vh;
}

.spline__heading {
    font-size: 9vw;
    color: white;
    position: absolute;
    bottom: -60px;
    left: 22%;
    font-weight: 700;
    font-family: 'IBM Plex Sans Condensed', serif;
    max-width: 400px;
    left: 4.5%;
    line-height: 100%;
    text-transform: uppercase;
}

.strategy__section {
    background-color: #0e0d0d;
}

.strategy__logo {
    position: absolute;
    left: 2%;
}

.strategy__landing {
    padding-top: 0 !important;
}

.strategy__logo img {
    width: 180px;
}

.strategy__navbar {
    position: absolute;
    right: 8%;
    top: 3%;
}

/* 
.strategy__navbar-list li {
    display: inline;
    color: white;
    cursor: pointer;
    padding: 8px 20px;
    border: 1px solid rgba(255, 255, 255, 0.495);
    border-radius: 5px;
    transition: all 0.8s ease;
    position: relative;
    overflow: hidden;
} */

.strategy__navbar-list li {
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.495);
    font-size: 18px;
    position: relative;
    border-radius: 150px;
    overflow: hidden;
    z-index: 10;
    transition: all 0.4s ease;
}

.strategy__navbar-list li::after {
    content: '';
    position: absolute;
    z-index: 5;
    height: 100%;
    width: 100%;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    opacity: 0.8;
    left: 0;
    bottom: -100%;
    border-radius: 50%;
    transition: all 0.4s ease;
}

.strategy__navbar-list li:hover {
    border: none;
}

.strategy__navbar-list li:hover::after {
    z-index: 5;
    bottom: 0;
    border-radius: 0;
}

.strategy__navbar-list {
    list-style-type: none;
    font-size: 1.2rem;
}

.strategy__navbar-list li span {
    margin-left: 0;
    position: relative;
    z-index: 15;
}

.get__quote--button {
    margin-bottom: 0;
    margin-top: 1.5rem;
    width: fit-content;
    color: #0f0c29;
    font-size: 1.5rem;
}

.get__quote--button li {
    font-size: 1.3rem;
    font-weight: 500;
    cursor: pointer;
    border: 2px solid #0f0c29;
    transition: all 0.4s ease;
}

.get__quote--button li:hover {
    color: white;
    border: 2px solid white;
}

.strategy__packages--button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.strategy__content {
    position: absolute;
    top: 44%;
    display: flex;
    flex-direction: column;
    left: 72%;
    color: white;
    gap: 0.5rem;
    overflow: hidden;
}

.strategy__content--we {
    position: absolute;
    top: 27%;
    align-items: center;
    display: flex;
    gap: 0.8rem;
    font-size: 2.7rem;
    left: 71.5%;
    color: white;
}

.strategy__content--text {
    font-size: 2.4rem;
    font-weight: 700;
}

.scroll-arrow {
    position: absolute;
    bottom: 1rem;
    right: -4.5%;
    border-radius: 5px;
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    padding: 10px 20px;
    color: white;
    font-weight: 600;
}

.scroll__down--chevron {
    display: flex;
    align-items: center;
}

.strategy__info {
    display: flex;
    align-items: center;
    justify-content: center;
}

.agency {
    display: flex;
    align-items: center;
    justify-content: center;
}

.agency__image {
    margin-right: 20px;
    display: grid;
    grid-template-columns: 1fr 1.8fr;
    gap: 2rem;
}

.strategy__curve--img {
    width: 500px;
    height: 100%;
    object-fit: cover;
    position: relative;
    overflow: hidden;
}

.agency__image-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s ease;
}

.agency__content {
    /* width: 100%; */
    color: white;
    font-family: Neue;
}

.agency__title {
    font-size: 2.8vw;
    line-height: 125%;
    font-weight: 400;
    margin: 0;
}

.agency__tagline {
    font-size: 2.8vw;
    font-weight: normal;
    line-height: 125%;
    margin: 0;
}

.agency__description {
    font-size: 2.8vw;
    line-height: 125%;
    margin: 0;
}

.curve__img--container {
    display: inline;
    position: relative;
    padding: 0 75px;
}

.curve__img--container2 {
    display: inline;
    position: relative;
    padding: 0 50px;
}

.curve__img {
    height: 50px;
    padding: 0 10px;
    position: absolute;
    left: 0;
    top: 5px;
}

.curve__img1 {
    height: 50px;
    padding: 0 10px;
    position: absolute;
    left: 0;
    top: 3px;
}

.strategy__packages {
    background-color: #efefef;
}

.strategy__container {
    max-width: 1500px;
    margin: 0 auto;
    padding: 80px 20px;
}

.strategy__packages--title {
    font-size: 80px;
    letter-spacing: 1px;
    margin: 0;
    font-family: 'IBM Plex Sans Condensed', sans-serif;
}

.strategy__packages--title span {
    display: block;
    margin: 0;
}

.strategy__info {
    padding: 0 0 40px;
}

.package__box {
    display: flex;
    align-items: center;
    font-family: NeueBold;
    font-size: 20px;
    width: fit-content;
    padding: 10px 10px;
    border-radius: 10px;
    position: relative;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    color: white;
    gap: 0.7rem;
}

.package__box--icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.package__box--icon img {
    width: 35px;
}

.strategy__packages--content {
    padding: 90px 0 0;
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    gap: 4rem;
}

.package__container {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
}

.package__plus {
    font-size: 30px;
    font-weight: 600;
    color: transparent; /* Make text transparent */
    position: relative;
    display: inline-block; /* Ensure proper positioning */
}

.package__plus::before {
    content: '+';
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
}

.package__left--title {
    font-size: 36px;
    font-family: NeueBold;
    margin: 0;
}

.package__right {
    color: #2e2f2a;
    letter-spacing: 1px;
}

.package__right-intro {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 1.15rem;
    margin-top: 0;
}

.package__right-list {
    list-style-type: disc;
    padding: 0;
    padding-left: 1.5rem;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 1.15rem;
    margin-top: 0;
}

.package__right-item {
    margin-bottom: 1.15rem;
}

.package__right-term {
    font-family: NeueBold;
}

.package__right-best-use {
    font-size: 24px;
    line-height: 32px;
    margin-top: 0;
}

.package__right-heading {
    font-family: NeueBold;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
}

.package__right-description {
    font-size: 24px;
    line-height: 36px;
    margin: 0;
}
.strategy__footer--section {
    background-color: #efefef;
}

.strategy__footer {
    background: #0f0c29; /* fallback for old browsers */

    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    overflow: hidden;
}

/* Inquiry Form */

.strategy__form {
    position: fixed;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 25;
    overflow-x: hidden;
    overflow-y: scroll;
}

.strategy__form::-webkit-scrollbar {
    width: 10px;
}
.strategy__form::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 7px;
}

.strategy__form--container {
    display: grid;
    grid-template-columns: 0.7fr 1.3fr;
    padding: 40px 20px;
}

.inquiry__form--socials {
    display: flex;
    gap: 1rem !important;
}

.inquiry__form--socials li {
    color: #333;
}

.inquiry__form--socials li:hover {
    color: #333;
    border: 2px solid #333;
}

.inquiry-form__group label {
    font-size: 1rem;
    font-weight: 500;
    color: #444;
}

.inquiry-form__field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.inquiry__form--socials li::after {
    height: 0px !important;
}

.inquiry__form--follow {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    letter-spacing: 1px;
}

.inquiry__close {
    font-size: 2rem;
    color: black;
    position: absolute;
    right: 0;
    top: 0;
}

.inquiry-form {
    background: white;
    padding: 30px;
    border-radius: 12px;
    font-family: 'Arial', sans-serif;
}

/* Title */
.inquiry-form__title {
    font-size: 2.8rem;
    letter-spacing: 1px;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
}

/* Input Group */
.inquiry-form__group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-bottom: 20px;
}

.inquiry-form__input {
    border: none;
    border-bottom: 2px solid #ddd;
    padding: 10px 0;
    font-size: 16px;
    color: #333;
    background: transparent;
}

.inquiry-form__input:focus {
    border-bottom: 2px solid #007bff;
    outline: none;
}

/* Labels */
.inquiry-form__label {
    font-size: 16px;
    font-weight: 500;
    color: #444;
    margin-top: 25px;
    margin-bottom: 15px;
}

/* Buttons for Interests and Budget */
.inquiry-form__interests,
.inquiry-form__budgets {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.inquiry-form__btn {
    padding: 10px 20px;
    font-size: 14px;
    border: 2px solid #696969;
    border-radius: 20px;
    text-transform: capitalize;
    font-weight: 500;
    background: white;
    color: #696969;
    cursor: pointer;
    transition: 0.3s ease;
}

/* Selected States */
.inquiry-form__btn--selected {
    background: #007bff;
    color: white;
    border: 2px solid #007bff;
    box-shadow: 0px 0px 8px rgba(14, 116, 144, 0.4);
}

/* Hover Effect */
.inquiry-form__btn:hover {
    border: 2px solid #007bff;
    background: #f8f8f8;
    color: #696969;
}

/* Textarea */
.inquiry-form__textarea {
    width: 90%;
    height: 100px;
    border: 2px solid #ddd;
    padding: 10px;
    font-size: 16px;
    border-radius: 20px;
    resize: none;
}

.inquiry-form__textarea:focus {
    border-color: #007bff;
    outline: none;
}

/* Submit Button */
.inquiry-form__submit {
    display: block;
    width: 100%;
    margin-top: 20px;
    background: #007bff;
    color: white;
    padding: 15px 30px;
    font-size: 18px;
    border: none;
    font-weight: 500;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s ease;
    width: fit-content;
    text-transform: capitalize;
}

.inquiry-form__submit:hover {
    background: #0064ce;
}

.footer__container {
    padding: 0 20px;
}

.footer__spline {
    height: 450px;
    padding-bottom: 90px;
    position: relative;
    z-index: 10;
    margin-bottom: 20px;
}

.footer__content {
    position: relative;
}

.footer__name {
    font-weight: bold;
    color: #d5d5d5;
    position: absolute;
    bottom: -5%;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding-top: 50px;
}

.footer__name h1 {
    font-family: 'IBM Plex Sans Condensed', sans-serif;
    margin: 0;
    text-align: center;
    font-size: 14rem;
    letter-spacing: 5px;
}

.footer__spline--box {
    width: 160px;
    height: 45px;
    background: #0f0c29;
    position: absolute;
    right: 17px;
    bottom: 110px;
    z-index: 15;
}

.tenx__spline {
    position: relative;
    z-index: 10;
}

.footer__logo {
    position: absolute;
    left: 2%;
    top: 1%;
}

.footer__logo img {
    width: 180px;
}

.strategy__footer--list {
    color: #d5d5d5;
    list-style-type: none;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    gap: 1.5rem;
    letter-spacing: 1px;
    padding-left: 0;
    margin: 0 0 5px;
}

.strategy__footer--list li {
    cursor: pointer;
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.strategy__footer--list li .social__icon {
    rotate: 45deg;
    transition: all 0.4s ease-in-out;
}

.strategy__footer--list li:hover .social__icon {
    transform: rotate(45deg);
}

.strategy__footer--list li::after {
    content: '';
    content: '';
    height: 2px;
    position: absolute;
    width: 100%;
    background-color: #d5d5d5;
    left: 0;
    bottom: -0.6rem;
    transform: scale(0);
    transform-origin: bottom left;
    transition: transform 0.3s ease-in-out;
}

.strategy__footer--list a {
    color: #d5d5d5;
}

.strategy__footer--list li:hover::after {
    transform-origin: bottom left;
    transform: scale(1);
}

.strategy__copyright {
    padding: 20px 0 30px;
    border-top: 1px solid #d5d5d524;
}

.copyright__container {
    padding: 0 20px;
}

.strategy__copyright--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.copyright__text {
    color: #d5d5d5;
    font-size: 16px;
    letter-spacing: 1px;
}

.copyright__text {
    display: flex;
    justify-content: space-between;
    align-items: end;
    text-align: center;
}

.get__quote--button {
    padding-left: 0;
}

@media (width<1025px) {
    .spline__heading {
        left: 0;
    }

    .strategy__content--we {
        top: 25%;
        left: 68.5%;
    }

    .strategy__content {
        left: 69%;
    }

    .strategy__packages--title {
        font-size: 75px;
    }

    .strategy__packages--content {
        gap: 2rem;
    }

    .strategy__logo {
        left: 0;
        margin-left: 0;
    }

    .strategy__navbar {
        right: 0;
    }

    .agency__title {
        padding-right: 7px;
        line-height: 40px;
        display: inline;
    }

    .agency__tagline {
        line-height: 40px;
        display: inline;
    }

    .curve__img--container {
        padding: 0 64px;
    }

    .curve__img {
        height: 43px;
        top: -4px;
    }

    .agency__description {
        line-height: 40px;
    }

    .curve__img1 {
        height: 43px;
        top: -3px;
    }

    .curve__img--container2 {
        padding: 0 46px;
    }

    .scroll-arrow {
        right: -6.5%;
    }

    .agency__tagline {
        font-size: 3vw;
    }

    .agency__description {
        font-size: 3vw;
    }

    .package__left--title h3 {
        margin: 30px 0;
    }

    .footer__name {
        z-index: 20;
    }

    .footer__name h1 {
        font-size: 13rem;
    }

    .agency__title {
        font-size: 3.2vw;
        line-height: 45px;
    }

    .agency__tagline {
        font-size: 3.2vw;
        line-height: 45px;
    }

    .agency__description {
        font-size: 3.2vw;
        line-height: 45px;
    }
}

@media (width< 1025px) and (height>1000px) {
    .strategy__globe {
        height: 60vh;
    }

    .strategy__content--we {
        top: 27%;
        left: 56.5%;
    }

    .strategy__content {
        left: 57%;
    }

    .strategy__curve--img {
        width: 450px;
    }

    .strategy__form--container {
        grid-template-columns: 1fr;
    }

    .inquiry-form {
        padding: 50px 0 0 0;
    }

    .inquiry__form--follow {
        margin-top: 1.5rem;
    }
}

@media (width<920px) {
    .footer__name h1 {
        font-size: 11rem;
    }

    .footer__name {
        bottom: -2%;
    }
    .strategy__curve--img {
        width: 325px;
    }
    .strategy__packages--title {
        font-size: 65px;
    }

    .package__left--title {
        font-size: 30px;
    }

    .package__box {
        font-size: 18px;
    }

    .package__box--icon img {
        width: 32px;
    }

    .package__right-intro,
    .package__right-list,
    .package__right-best-use {
        font-size: 22px;
    }

    .strategy__form--container {
        grid-template-columns: 1fr;
    }

    .inquiry-form {
        padding: 50px 0 0 0;
    }

    .inquiry__form--follow {
        margin-top: 1.5rem;
    }
}

@media (width<790px) {
    .footer__name h1 {
        font-size: 9rem;
    }

    .footer__spline {
        padding-bottom: 50px;
    }

    .footer__spline--box {
        bottom: 69px;
    }

    .agency__title,
    .agency__description,
    .agency__tagline {
        font-size: 3vw;
        line-height: 40px;
    }

    .package__box--icon img {
        width: 28px;
    }
}

@media (width< 640px) {
    .strategy__landing {
        padding: 0;
    }

    .inquiry-form__group {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .spline__heading {
        font-size: 5rem;
        color: white;
        position: absolute;
        bottom: 40px;
    }

    .scroll-arrow {
        right: -17.5%;
    }

    .strategy__globe {
        height: 80vh;
    }

    .strategy__content {
        left: 3%;
    }
    .spline__heading {
        left: 3%;
    }

    .strategy__content--we {
        left: 3%;
    }

    .strategy__curve--img {
        display: none;
    }

    .agency__image {
        display: block;
    }

    .agency__title,
    .agency__tagline,
    .agency__description {
        font-size: 2.14rem;
        line-height: 45px;
    }

    .strategy__logo img {
        width: 160px;
    }

    .strategy__logo {
        left: 3%;
    }

    .strategy__content--we {
        top: 21%;
    }

    .strategy__content {
        top: 39%;
    }
    .strategy__navbar {
        right: 3%;
        top: 1%;
    }

    .strategy__packages--title {
        font-size: 54px;
    }

    .strategy__packages--content {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .agency {
        padding-bottom: 50px;
    }

    .strategy__packages--content {
        padding-top: 75px;
    }

    .footer__spline {
        padding-bottom: 21px;
        padding-top: 20px;
        height: 340px;
    }

    .footer__container {
        padding: 0;
    }

    .footer__spline--box {
        bottom: 34px;
    }

    .footer__name h1 {
        font-size: 5rem;
    }

    .footer__links {
        display: none;
    }
    .strategy__packages--title {
        font-size: 50px;
    }

    .strategy__footer--list li {
        font-size: 1.1rem;
    }
    .inquiry-form__title {
        font-size: 2.4rem;
    }
}

@media (width< 390px) {
    .strategy__globe {
        height: 100vh;
    }

    .strategy__footer--list li {
        font-size: 1rem;
    }
}
