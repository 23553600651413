.featureList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    width: 100%;
    margin-top: 67px;
}

.featureItem {
    display: flex;
    align-items: start;
    gap: 10px;
}

.featureIcon {
    color: blue;
    margin-top: 5px; /* Adjust vertical alignment if needed */
}

.featureText {
    display: flex;
    flex-direction: column;
}

.featureTitle {
    margin: 0;
    font-weight: bold;
}

.featureDescription {
    margin: 0;
}
/* Media query for mobile devices */
@media (max-width: 767px) {
    .featureList {
        grid-template-columns: 1fr;
    }
}
