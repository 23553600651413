.HeroSection {
    background-color: rgb(246, 244, 247);
}

/* About section */
.body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5em;
    font-family: var(--primary-font-family);
    padding-bottom: 6em;
    max-width: 1680px;
    margin: 0 auto;
    width: 100%;
}
.body .LeftSection {
    width: 45%;
    position: relative;
}
.body .LeftSection div img {
    position: relative;
    z-index: 1;
    width: 96%;
}
.experienceDiv {
    position: absolute;
    background: var(--hap-gradient-color-reverse);
    width: 80px;
    padding: 15px;
    font-size: 20px;
    color: white;
    border-radius: 30px;
    bottom: -15%;
    right: 5%;
    z-index: 1;
}
.experienceDiv span {
    font-size: 30px;
    font-weight: 700;
}
.experienceDiv p {
    margin: 0;
    font-size: 15px;
}
.CurveDiv {
    position: absolute;
    right: 0%;
    top: -20%;
    width: 150px;
    transform: rotate(20deg);
    z-index: 1;
}
.LineDiv {
    position: absolute;
    left: -5%;
    top: 5%;
    z-index: 0;
}
.RightSection {
    width: 50%;
}
.RightSection h4 {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    /* text-align: center; */
}
.RightSection h1 {
    margin: 10px auto;
    font-size: 70px;
    /* text-align: center; */
}
.RightSection p {
    font-size: 15px;
    text-align: justify;
    line-height: 1.7em;
}
.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 5px;
}
.gridItem {
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    display: flex;
    padding: 25px 15px;
    gap: 10px;
}
.icons {
    width: 20%;
}
.icons img {
    width: 100%;
    animation: floatAnimation 3s ease-in-out infinite;
}
@keyframes floatAnimation {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(
            5px
        ); /* Adjust the horizontal float distance as needed */
    }
}
.text {
    width: 70%;
    animation: floatAnimation 3s ease-in-out infinite;
}
.text p {
    font-size: 18px;
    font-weight: 500;
}
.btn {
    flex: 1 1 auto;
    margin: 30px 10px 20px 10px;
    padding: 20px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    border: none;
    display: inline-block;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    font-size: 15px;
    font-weight: 600;
}
.btn:hover {
    --x: 100%;
    cursor: pointer;
}
#btn1 {
    --c1: #c600ff;
    --c2: #007bff;
    color: white;
}

/*  */

.AboutVision {
    background-color: var(--footer-bg);
}

.VisionDiv {
    /* height: 100vh; */

    display: flex;
    position: relative;
    z-index: 0;
    /* margin-top: 10em; */
    padding: 4em 0;
    width: 100%;
    justify-content: space-between;
    font-family: var(--primary-font-family);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    max-width: 1680px;
    margin: 0 auto;
    width: 100%;
}
.VisionDiv::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--footer-bg);
    /* background-image: url("../../img/Background/aboutUS-BG.webp"); */
    background-size: cover;
    transform-origin: 0 100%; /* Set the origin of the skew transformation */

    z-index: -1;
}
.VisionDiv h4 {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    /* text-align: center; */
}
.VisionDiv h1 {
    font-size: 70px;
    color: white;
    margin: 10px 0;
}
.innerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4em;
    padding: 2em 0;
}
.innerDiv .LeftSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    gap: 1.5em;
}
.innerDiv .LeftSection hr {
    width: 90%;
    background: var(--hap-gradient-color-reverse);
    height: 2px;
    border: none;
}
.items {
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon {
    width: 25%;
}
.icon img {
    width: 70%;
}
.text {
    width: 60%;
}
.text p {
    font-size: 18px;
}
.innerDiv .RightSection {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}
.innerDiv .RightSection img {
    width: 70%;
    height: inherit;
}

.MissionDiv {
    display: flex;
    justify-content: center;
    padding: 3em 0 2em 0;
    gap: 2em;
}
.MissionDiv .LeftSection {
    width: 43%;
}
.MissionDiv .LeftSection img {
    width: 100%;
}
.MissionDiv .RightSection {
    width: 43%;
}
.MissionDiv .RightSection p {
    font-size: 18px;
    text-align: justify;
}

/* get */
.outerDiv {
    background-image: url('../../img/Background/Component\ 17.webp');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    height: 250px;
    font-family: var(--primary-font-family);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -60px;
    padding-top: 60px;
}
.leftdiv {
    width: 60%;
}
.leftdiv h4 {
    font-size: 25px;
    color: white;
}
.leftdiv p {
    color: white;
    font-size: 20px;
}
.rightdiv {
    width: 20%;
}
button {
    border: 1px solid white;
    padding: 20px 25px;
    border-radius: 30px;
    background-color: transparent;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
}

button:hover {
    cursor: pointer;
}
@media only screen and (max-width: 1200px) {
    .body h1,
    .VisionDiv h1 {
        font-size: 55px;
    }
    .body h4,
    .VisionDiv h4 {
        font-size: 15px;
    }
    .body p {
        font-size: 15px;
    }
}
@media only screen and (max-width: 1000px) {
    .body {
        padding-top: 3em;
    }
    .body h1,
    .VisionDiv h1 {
        font-size: 45px;
        text-align: center;
    }
    .body h4,
    .VisionDiv h4 {
        font-size: 15px;
        text-align: center;
    }
    .body p {
        font-size: 15px;
    }
    .body {
        flex-wrap: wrap-reverse;
    }
    .innerDiv {
        flex-wrap: wrap;
        gap: 2em;
        margin-top: 0;
        padding-top: 1.5em;
    }
    .innerDiv .LeftSection,
    .MissionDiv .RightSection {
        width: 90%;
    }
    .MissionDiv .RightSection p {
        font-size: 15px;
    }
    .MissionDiv .LeftSection {
        display: none;
    }
    .innerDiv .RightSection {
        display: none;
    }
    .LeftSection {
        width: 100%;
    }
    .body .LeftSection .CurveDiv {
        width: 30%;
    }
    .body .LeftSection .experienceDiv span {
        font-size: 20px;
    }
    .body .LeftSection .experienceDiv p {
        font-size: 14px;
    }

    .body .LeftSection {
        width: 75%;
    }
    .RightSection {
        width: 90%;
    }
}
@media only screen and (max-width: 850px) {
    .body h1,
    .VisionDiv h1 {
        font-size: 45px;
    }
    .body h4,
    .VisionDiv h4 {
        font-size: 15px;
    }
    .column {
        grid-column: span 2;
    }
    .body p {
        font-size: 14px;
    }
    .body .LeftSection .experienceDiv {
        padding: 10px;
    }
    .body .LeftSection .experienceDiv span {
        font-size: 18px;
    }
    .body .LeftSection .experienceDiv p {
        font-size: 15px;
    }
    .outerDiv {
        /* flex-wrap: wrap; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .outerDiv .leftdiv {
        width: 60%;
    }
    .outerDiv .rightdiv {
        width: 60%;
    }
    .outerDiv .leftdiv h4 {
        font-size: 20px;
    }
    .outerDiv .leftdiv p {
        font-size: 20px;
    }
}
/* @media only screen and (max-width: 700px) {
    .LeftSection {
        display: none;
    }
} */
@media only screen and (max-width: 600px) {
    .body h1,
    .VisionDiv h1 {
        font-size: 40px;
    }

    .body {
        padding-bottom: 4em;
    }
    .VisionDiv {
        padding-bottom: 1rem !important;
    }
    .body h4,
    .VisionDiv h4 {
        font-size: 15px;
    }
    .btn {
        font-size: 13px;
        padding: 15px 15px;
    }
    .body p {
        font-size: 15px;
    }
    .body .LeftSection .experienceDiv span {
        font-size: 15px;
    }
    .body .LeftSection .experienceDiv p {
        font-size: 13px;
    }

    .outerDiv .leftdiv {
        width: 80%;
    }
    .outerDiv .rightdiv {
        width: 80%;
    }
    .outerDiv .leftdiv h4 {
        font-size: 20px;
    }
    .outerDiv .leftdiv p {
        font-size: 20px;
    }
    .innerDiv .LeftSection {
        width: 85%;
    }
    .MissionDiv .RightSection {
        width: 90%;
    }
}
@media only screen and (max-width: 450px) {
    .body {
        padding-top: 3em;
    }
    .body h1,
    .VisionDiv h1 {
        font-size: 30px;
    }
    .body h4,
    .VisionDiv h4 {
        font-size: 15px;
    }
    .Form .column {
        font-size: 15px;
    }
    .column input,
    .column textarea {
        width: 90%;
    }
    .body p {
        font-size: 13px;
    }
    .innerDiv p {
        font-size: 13px;
    }
    .MissionDiv .RightSection p {
        font-size: 15px;
    }

    .outerDiv .leftdiv {
        width: 80%;
    }
    .outerDiv .rightdiv {
        width: 80%;
    }
    .outerDiv .leftdiv h4 {
        font-size: 17px;
    }
    .outerDiv .leftdiv p {
        font-size: 17px;
    }
    .outerDiv .rightdiv button {
        font-size: 14px;
        padding: 10px 15px;
    }
    .innerDiv .LeftSection {
        width: 90%;
    }

    .MissionDiv .RightSection {
        width: 90%;
    }
}
@media only screen and (max-width: 300px) {
    /* .outerDiv {
    justify-content: center;
    align-items: center;
  }
  .outerDiv .leftdiv {
    width: 60%;
  }
  .outerDiv .rightdiv {
    width: 30%;
  } */
}
@media only screen and (max-width: 281px) {
    .body {
        padding-top: 3em;
    }
    .body h1,
    .VisionDiv h1 {
        font-size: 25px;
    }
    .body h4,
    .VisionDiv h4 {
        font-size: 15px;
    }
    .column input,
    .column textarea {
        width: 80%;
    }
    .column select {
        width: 95%;
    }
    .outerDiv .leftdiv h4 {
        font-size: 15px;
    }
    .outerDiv .leftdiv p {
        font-size: 15px;
    }
}
@media only screen and (min-width: 701px) and (max-width: 1023px) {
    .body .LeftSection {
        margin-top: 0px;
    }
}
@media only screen and (max-width: 992px) {
    .body .LeftSection {
        margin-top: 0px;
    }
    .aboutVideo {
        border-radius: 15px !important;
    }
    .homeVideo {
        border-radius: 15px !important;
    }
}
@media only screen and (max-width: 729px) {
    .body .LeftSection {
        width: 90%;
        justify-content: center !important;
    }
    .innerDiv .RightSection {
        width: 80%;
        justify-content: center !important;
    }
    .homeVideo {
        width: 100%;
    }
}
@media only screen and (max-width: 1023px) {
    .experienceDiv {
        display: none;
    }
}

.homeVideo {
    width: 90%;
    height: auto;
    border-radius: 50px;
}
.aboutVideo {
    width: 70%;
    height: auto;
    border-radius: 50px;
}
