.selected .Rounded {
  color: white;
  background-color: #007bff;
  border: 2px solid #007bff;
}
.selected .customline {
  color: white;
  background-color: #007bff;
  border: 2px solid #007bff;
}
.nodisplay {
  display: none;
}
.text {
  color: #007bff;
}
.TopLine {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}
.outerSection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.OuterinnerSection {
  position: relative;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  color: #ccc;
}
.Rounded {
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  border: 2px solid #ccc;
  height: 12px;
  width: 12px;
  padding: 5px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
}
.customline {
  flex: 1;
  width: 40px;
  border-top: 2px solid #ccc;
  transition: all 0.5s ease-in-out;
}
@media only screen and (max-width: 1120px) {
  .customline {
    width: 30px;
  }
}
@media only screen and (max-width: 950px) {
  .customline {
    width: 20px;
  }
}
@media only screen and (max-width: 780px) {
  .Rounded {
    width: 10px;
    height: 10px;
  }
  .customline {
    width: 15px;
  }
}
@media only screen and (max-width: 650px) {
  .Rounded {
    width: 8px;
    height: 8px;
  }
  .customline {
    width: 13px;
  }
}
@media only screen and (max-width: 580px) {
  .Rounded {
    width: 5px;
    height: 5px;
  }
  .customline {
    width: 10px;
  }
}
@media only screen and (max-width: 460px) {
  .Rounded {
    width: 3px;
    height: 3px;
  }
  .customline {
    width: 5px;
  }
}
@media only screen and (max-width: 400px) {
  .Rounded {
    width: 2px;
    height: 2px;
  }
  .customline {
    width: 3px;
  }
}
@media only screen and (max-width: 320px) {
  .Rounded {
    width: 2px;
    height: 2px;
  }
  .customline {
    width: 1px;
  }
}
@media only screen and (max-width: 280px) {
  .Rounded {
    width: 1px;
    height: 1px;
  }
  .customline {
    width: 1px;
  }
}
