.Footer {
    background-color: var(--footer-bg);
    color: white;
    display: flex;
    flex-direction: column;
    font-family: var(--primary-font-family);
    padding-top: 2em;
    overflow: hidden;
}

.container {
    max-width: 1680px;
    margin: 0 auto;
    width: 100%;
}
.IntroSection {
    width: 25%;
}
.IntroSection img {
    margin-top: 1.5em;
    width: 50%;
}
.IntroSection p {
    text-align: justify;
    color: #7f7f7f;
}
.pictures {
    display: flex;
    gap: 13px;
    justify-content: flex-start;
    align-items: center;
    margin-top: -30px;
    margin-left: -18px;
}
.pictures img {
    width: 35%;
}
.LeftSection {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.LeftSection div {
    padding: 5px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.135);
}
.LeftSection div a {
    text-decoration: none;
    color: white;
}
.LeftSection div a:hover {
    color: var(--hap-base-color);
}
.RightSection {
    width: 25%;
    display: flex;
    flex-direction: column;
}
.RightSection img {
    width: 90%;
}
.contactDetails {
    display: flex;
    align-items: center;
}

.contactDetails a {
    text-decoration: none;
    color: inherit;
}

.contactDetails svg {
    margin-right: 10px;
}
.contactDetails a:hover {
    color: var(--hap-base-color);
}
.contactDetails p {
    word-break: break-all;
}

.copyright {
    display: flex;
    width: 100%;
    align-items: center;
    border-top: 1px solid rgba(128, 128, 128, 0.135);
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
}

.copyright p {
    padding: 20px 25px 0;
    text-align: center;
    font-size: 12px;
    line-height: 180%;
    width: 65%;
    margin: 0;
    color: #aeaeae;
}
.Services {
    border-radius: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    flex-direction: column;
}

@media only screen and (max-width: 1100px) {
    .IntroSection {
        width: 40%;
    }
    .RightSection {
        width: 90%;
        gap: 3em;
        flex-direction: row;
    }
    .LeftSection {
        width: 40%;
    }
    .container {
        flex-wrap: wrap;
        gap: 20px;
    }
}
@media only screen and (max-width: 600px) {
    .IntroSection {
        width: 80%;
    }
    .RightSection {
        width: 80%;
        flex-direction: column;
    }
    .LeftSection {
        width: 80%;
    }
    .container {
        gap: 20px;
    }
}

@media only screen and (max-width: 500px) {
    .pictures {
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-top: 0;
        margin-left: 0;
    }

    .pictures img {
        width: 25%;
    }
}

@media only screen and (max-width: 300px) {
    h2 {
        font-size: 20px;
    }
}

.footerContent {
    display: flex;
    justify-content: space-between;
    padding: 0 4rem 4rem;
}

.footerMenu {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 20px;
}

.footerContactDetails {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 1rem;
}

.footerContactDetails i {
    color: var(--hap-base-color);
    font-size: 1.3rem;
}

.footerLocation {
    padding-left: 0.4rem;
    max-width: 320px;
}

.footerPhone {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.footerTitle {
    margin: 0;
}

.footerContactDetails a:hover {
    color: var(--hap-base-color);
}

.footerPictures {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.footerPictures img {
    width: 100px;
    object-fit: contain;
}

@font-face {
    font-family: 'Futura Pt Cond';
    src: url('https://uploads-ssl.webflow.com/6608172cae06109256985a54/6608172dae06109256985b3e_FuturaPTCond-ExtraBold.ttf')
        format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

.footerSocial {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    align-items: center;
}

.footerMarqueeContainer {
    padding: 3rem 0;
    max-width: 85vw;
    overflow: hidden;
}

.footerName {
    padding-top: 1rem;
    padding-bottom: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footerNameText {
    font-size: 20vw;
    margin: 0;
    font-weight: 600;
    font-family: Futura Pt Cond;
    line-height: 17vw;
}

.footerMarqueeContainer > .marquee > .initial-child-container > .child {
    width: 17vw !important;
}

.footerNameText span {
    font-style: italic;
}

.footerTerms {
    display: flex;
    justify-content: center;
    gap: 0.8rem;
    padding: 0 0 25px;
    font-size: 12px;
}

.footerTerms a:hover {
    color: #aeaeae;
    text-decoration: none;
}

.footerAbn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.8rem;
}

@media (width< 992px) {
    .footerContent {
        padding: 0 2rem 2rem;
    }
}

@media (width<768px) {
    .footerContent {
        flex-direction: column;
    }

    .footerPictures img {
        width: 80px;
    }

    .footerPictures {
        justify-content: flex-start;
        margin-top: 0.5rem;
    }

    .footerSocial {
        font-size: 14px;
        justify-content: flex-start;
    }

    .footerAbn {
        justify-content: flex-start;
        margin-top: 1rem;
    }

    .footerAbn p {
        margin: 0;
        font-size: 14px;
    }

    .footerNameText {
        font-size: 10rem;
        line-height: 100%;
    }

    .footerTextTech {
        padding-right: 10px !important;
    }

    .copyright p {
        width: 80%;
    }
}
