.ServicesContainer {
    background-image: url('../../img/Background/test-bg2.webp');
}

.firstDiv {
    font-family: var(--primary-font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2em;
    max-width: 1680px;
    margin: 0 auto;
    width: 100%;
}
.firstDiv h4 {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}
h1.textnew {
    font-size: 40px !important;
}

.firstDiv h1 {
    font-size: 65px;
    margin: 0;
    text-align: center;
}

.services {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    margin-top: 3em;
    flex-wrap: wrap;
    gap: 50px;
    margin-bottom: 7em;
}
.CardOuter {
    padding: 40px 60px;
    border-radius: 10px;
    background: rgb(0, 123, 255);
    background: linear-gradient(
        151deg,
        rgba(0, 123, 255, 0.5) 28%,
        rgba(198, 0, 255, 0.5) 82%
    );
    width: fit-content;
}

.servicesCards {
    display: flex;
    justify-content: center;
    align-items: center;
}
.CardOuterR {
    padding: 40px 60px;
    border-radius: 10px;
    background: linear-gradient(
        151deg,
        rgba(0, 123, 255, 0.5) 28%,
        rgba(198, 0, 255, 0.5) 82%
    );
    width: fit-content;
}
.services .Card,
.services .HoverClick,
.services .CardR,
.services .HoverClickR {
    /* padding: 70px 30px 45px; */
    width: 420px;
    height: 470px;
    border-radius: 10px;
    position: relative;
    transition: all 1s ease;
    background-color: rgb(246, 244, 247);
    overflow: hidden;
    margin-left: -170px;
}
.services .CardR,
.services .HoverClickR {
    margin: 0;
    margin-right: -170px;
}
.rectangle {
    width: 400px;
    height: 800px;
    position: absolute;
    top: 33%;
    left: 2%;
    background-color: #007bff55;
    transform: rotate(-55deg);
    transition: all 1s ease;
}
.HoverClick .rectangle,
.HoverClickR .rectangle {
    left: -100%;
    bottom: -100%;
}
/* .Card,
.CardR {
  background-image: url("../../img/Background/background2.jpg");
  background-size: cover;
  border: 1px solid rgb(115, 115, 115);
} */
.Cardbg {
    background-image: url('../../img/Background/background2.webp');
}
.CardAfterClick {
    background-image: url('../../img/Background/background2.webp');
}
.Card img,
.HoverClick img,
.CardR img,
.HoverClickR img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: all 1s ease;
}
.numberDiv {
    position: absolute;
    right: 8%;
    top: 5%;
    transition: all 1s ease;
}
.numberDiv p {
    text-transform: uppercase;
    font-size: 80px;
    margin: 0;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(246, 244, 247);
    transition: all 1s ease;
}
.Icons {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    position: absolute;
    top: 20%;
    left: 5%;
    background-color: var(--hap-base-color);
    transition: all 1s ease;
    z-index: 2;
}
.circle1,
.circle2,
.circle3,
.circle4 {
    position: absolute;
    bottom: -15px;
    right: -15px;
    height: 70px;
    width: 70px;
    background-size: contain;
    transition: all 1s ease;
}
.circle1 {
    background-image: url('../../img/Flaticon/application.webp');
}
.circle2 {
    background-image: url('../../img/Flaticon/brand.webp');
}
.circle3 {
    background-image: url('../../img/Flaticon/bullhorn.webp');
}
.circle4 {
    background-image: url('../../img/Flaticon/mobile.webp');
}
.text {
    position: absolute;
    bottom: 5%;
    left: 10%;
    right: 10%;
}

.text h2 {
    color: white;
    text-transform: uppercase;
}
.text p {
    color: white;
}

.para {
    color: black;
    display: flex;
    align-items: center;
    gap: 10px;
}
.arrowCircle {
    margin-top: 30px;
    font-size: 40px;
    color: white;
}
.HoverClick .text h2,
.HoverClickR .text h2 {
    color: black;
}
.black {
    color: black;
}
.white {
    color: white;
}
.HoverClick .Icons,
.HoverClickR .Icons {
    background-color: #007bff55;
    top: 10%;
    left: 5%;
}
.HoverClick .circle1 {
    background-image: url('../../img/Flaticon/application1.webp');
}
.HoverClickR .circle2 {
    background-image: url('../../img/Flaticon/brand1.webp');
}
.HoverClick .circle3 {
    background-image: url('../../img/Flaticon/bullhorn1.webp');
}
.HoverClickR .circle4 {
    background-image: url('../../img/Flaticon/mobile1.webp');
}
.HoverClick .arrowCircle,
.HoverClickR .arrowCircle {
    color: var(--hap-base-color);
}
.HoverClick .text,
.HoverClick .text p,
.HoverClickR .text p,
.HoverClickR .text {
    color: rgb(78, 78, 78);
    bottom: 7%;
}

.HoverClickRo .text p,
.HoverClickRo .text {
    color: rgb(78, 78, 78);
    bottom: 20%;
}
.Card:hover img,
.CardR:hover img {
    /* opacity: 0; */
}

.HoverClick .numberDiv p,
.HoverClickR .numberDiv p {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #16232be1;
}

.Ideas {
    background-color: #16232b0d;
}

@media only screen and (max-width: 1200px) {
    .firstDiv h1 {
        font-size: 55px;
    }
    .firstDiv h4 {
        font-size: 15px;
    }
}

@media only screen and (max-width: 1100px) {
    .services {
        grid-template-columns: 1fr;
    }
}
@media only screen and (max-width: 1000px) {
    .firstDiv h1 {
        font-size: 45px;
    }
    .firstDiv h4 {
        font-size: 15px;
    }
}
@media only screen and (max-width: 850px) {
    .firstDiv h1 {
        font-size: 45px;
    }
    .firstDiv h4 {
        font-size: 15px;
    }
    .column {
        grid-column: span 2;
    }
    .services .Card,
    .services .HoverClick,
    .services .CardR,
    .services .HoverClickR {
        width: 400px;
        height: 450px;
    }
    .text h2 {
        font-size: 23px;
    }
    .Icons {
        height: 60px;
        width: 60px;
    }
    .circle1,
    .circle2,
    .circle3,
    .circle4 {
        height: 60px;
        width: 60px;
    }
    .text {
        position: absolute;
        bottom: 5%;
        left: 7%;
        right: 7%;
    }
    h1.textnew {
        font-size: 30px !important;
    }
}
@media only screen and (max-width: 630px) {
    .firstDiv h1 {
        font-size: 40px;
    }
    .firstDiv h4 {
        font-size: 10px;
    }
    .btn {
        font-size: 13px;
        padding: 15px 15px;
    }
    .services .Card,
    .services .HoverClick,
    .services .CardR,
    .services .HoverClickR {
        width: 350px;
        height: 430px;
        margin-left: 0;
    }

    .services {
        width: 100%;
    }
    h1.textnew {
        font-size: 25px !important;
    }

    .services {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 500px) {
    .CardOuter,
    .CardOuterR {
        width: 100%;
        display: flex;
        padding: 20px;
        align-items: center;
    }
    .Icons {
        height: 50px;
        width: 50px;
        top: 10%;
    }
    .circle1,
    .circle2,
    .circle3,
    .circle4 {
        height: 50px;
        width: 50px;
    }

    .ServicesContainer {
        padding: 0 20px;
    }

    .services {
        margin-bottom: 4em;
    }
}
@media only screen and (max-width: 450px) {
    .firstDiv h1 {
        font-size: 30px;
    }
    .firstDiv h4 {
        font-size: 12px;
    }
    .Form .column {
        font-size: 15px;
    }
    .column input,
    .column textarea {
        width: 90%;
    }
    .text h2 {
        font-size: 20px;
    }
    .text h3 {
        font-size: 15px;
    }
    .para {
        font-size: 14px;
    }
    .arrowCircle {
        font-size: 30px;
    }
}
@media only screen and (max-width: 400px) {
    .text h2 {
        font-size: 18px;
    }
    .text h3 {
        font-size: 15px;
    }
    .text p {
        font-size: 14px;
    }
    .para {
        font-size: 14px;
    }
    .arrowCircle {
        /* display: none; */
    }
    .Icons {
        height: 50px;
        width: 50px;
        top: 20%;
    }

    .services .Card,
    .services .HoverClick,
    .services .CardR,
    .services .HoverClickR {
        width: 300px;
        height: 430px;
        margin-left: 0;
    }
}
@media only screen and (max-width: 360px) {
    .CardOuter,
    .CardOuterR {
        width: 100%;
        display: flex;
        padding: 10px;
    }
}
@media only screen and (max-width: 281px) {
    .firstDiv h1 {
        font-size: 25px;
    }
    .firstDiv h4 {
        font-size: 13px;
    }
    .column input,
    .column textarea {
        width: 80%;
    }
    .column select {
        width: 95%;
    }
    .text h2 {
        font-size: 18px;
    }
    .text h3 {
        font-size: 13px;
    }
    .text p {
        font-size: 13px;
    }
    .para {
        font-size: 13px;
    }
}
