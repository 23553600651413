:root {
  --heading: "RecoletaAlt";
  --heading-2: "Unbounded";
  --heading-3: "Space Grotesk", sans-serif;
  --body-font: "Inter";
  --body-color: #555555;
  --base-color2: #ff3838;
  --base-color: #6d55e9;
  --title-color: #0e0e0e;
  --body-bg-color: #171926;
  --hap-body-color: #0d0d0d;
  --hap-base-color: #007bff;
  --hap-title-color: #000000;
  --footer-bg: #1e1e1e;
  --hap-gradient-color: linear-gradient(to right, #007bff 0%, #c600ff 100%);
  --hap-gradient-color-reverse: linear-gradient(
    to right,
    #c600ff 0%,
    #007bff 100%
  );

  --primary-font-family: "Space Grotesk", Sans-serif;
  --secondary-font-family: "Cagliostro", sans-serif;
}
