.Pricing {
    margin-top: 2rem;
    font-family: var(--primary-font-family);
    text-align: center;
    z-index: 1;
}
.Heading {
    display: flex;
    justify-content: center;
    align-items: center;
}
.PriceButton {
    margin-left: 60px;
    margin-bottom: 20px;
}
.Heading img {
    width: 2%;
}
.Pricing h4 {
    background: var(--hap-gradient-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Pricing h1 {
    font-size: 65px;
    margin: 0;
}
.SelectionDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-wrap: wrap; */
    margin: 3em;
    gap: 20px;
}

.SelectionDiv .notActive {
    border: none;
    font-size: 18px;
    font-weight: 500;
    color: #151f35;
    background: transparent;
}
.activePlan {
    background: var(--hap-gradient-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    font-weight: 500;
    transition: all 1s ease;
    border: none;
    position: relative;
    text-decoration: none; /* Remove the default underline */
    display: inline-block; /* Make sure the width of the underline matches the text */
}
.activePlan::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    width: 100%;
    height: 3px;
    background: var(--hap-gradient-color);
    transition: width 0.5s ease;
}
.activePlan:hover::after {
    width: 0%;
}
.PricingDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    text-align: left;
}
.CombinedAnimation {
    display: flex;
    align-items: center;
    gap: 2em;
    text-align: center;
    justify-content: center;
    width: 100%;
}
.LeftSection {
    width: 50%;
    border-radius: 20px;
    height: 520px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.LeftSection1 {
    background-image: url('../../img/Home/Component8.webp');
}
.LeftSection2 {
    background-image: url('../../img/Home/Component.webp');
}
.LeftSection3 {
    background-image: url('../../img/Home/Component45.webp');
}
.PriceCircle {
    background: var(--hap-gradient-color);
    height: 130px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    border-radius: 50%;
    flex-direction: column;
    margin-left: 20px;
    margin-bottom: 20px;
}
.PriceCircle p,
.PriceText p {
    margin: 0;
    padding: 0;
    word-wrap: break-word;
    font-size: 20px;
}
.PriceCircle p:nth-child(1) {
    font-size: 25px;
    font-weight: 600;
}
.PriceText {
    color: white;
    width: 70%;
    margin-left: 20px;
    margin-bottom: 20px;
}
.PriceText h2 {
    font-size: 30px;
    margin-bottom: 0.5em;
}
.PriceText p {
    background: var(--hap-gradient-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1em;
}
/* Right Setion */
.RightSection {
    width: 40%;
    border-radius: 20px;
    background-image: url('../../img/Background/PricingBackground.webp');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0;
}
.RightSection ul {
    list-style: none;
    position: relative;
    font-weight: 500;
    text-align: left;
}
.RightSection ul:before {
    top: -5px;
    left: 10%;
    width: 1px;
    content: '';
    height: 100%;
    position: absolute;
    background-color: #c5d7e3;
}
.RightSection li {
    padding-left: 40px;
    line-height: 35px;
    position: relative;
    list-style: none;
}
.RightSection li:before {
    left: 1.5%;
    top: 30%;
    width: 13px;
    height: 13px;
    content: '';
    position: absolute;
    border-radius: 100%;
    background: var(--hap-gradient-color);
}

.btn {
    flex: 1 1 auto;
    margin-left: 50px;
    margin-bottom: 10px;
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    transition: 0.5s;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px #eee;
    border-radius: 20px;
    border: none;
}
.btn:hover {
    --x: 100%;
    cursor: pointer;
}
#btn1 {
    --c1: #c600ff;
    --c2: #007bff;
    color: white;
}
@media only screen and (max-width: 1150px) {
    .Heading h1 {
        font-size: 55px;
    }
    .Heading h4 {
        font-size: 14px;
    }
    .PriceCircle {
        height: 120px;
        width: 120px;
    }
    .PriceCircle p,
    .PriceText p {
        font-size: 17px;
    }
    .PriceCircle p:nth-child(1) {
        font-size: 22px;
    }
    .PriceText {
        text-align: left;
    }
    .PriceText h2 {
        font-size: 28px;
    }
}
@media only screen and (max-width: 1000px) {
    .Pricing h1 {
        font-size: 55px;
    }
    .Pricing h4 {
        font-size: 14px;
    }
    .SelectionDiv .notActive,
    .SelectionDiv .activePlan {
        font-size: 15px;
    }
    .RightSection ul:before {
        width: 0;
    }
}
@media only screen and (max-width: 930px) {
    .Pricing h1 {
        font-size: 55px;
    }
    .Pricing h4 {
        font-size: 14px;
    }
    /*  */
    .CombinedAnimation {
        flex-wrap: wrap;
    }
    .LeftSection {
        width: 80%;
        height: 350px;
        display: flex;
        justify-content: flex-start;
    }
    .RightSection {
        width: 80%;
    }
    .RightSection ul {
        text-align: left;
    }
}
@media only screen and (max-width: 840px) {
    .Pricing h1 {
        font-size: 50px;
    }
    .Pricing h4 {
        font-size: 14px;
    }
}
@media only screen and (max-width: 750px) {
    .Pricing {
        margin-top: 4em;
    }
}
@media only screen and (max-width: 630px) {
    .Pricing h1 {
        font-size: 40px;
    }
    .Pricing h4 {
        font-size: 13px;
    }
    .SelectionDiv .notActive,
    .SelectionDiv .activePlan {
        font-size: 13px;
    }
    .PriceCircle {
        height: 120px;
        width: 120px;
    }
    .PriceCircle p,
    .PriceText p {
        font-size: 15px;
    }
    .PriceCircle p:nth-child(1) {
        font-size: 20px;
    }
    .PriceText h2 {
        font-size: 25px;
    }
    .RightSection li {
        font-size: 13px;
        line-height: 30px;
    }
}
@media only screen and (max-width: 580px) {
    .CombinedAnimation {
        gap: 1em;
    }
    .LeftSection {
        height: 300px;
    }
    .Pricing h1 {
        font-size: 40px;
    }
    .Pricing h4 {
        font-size: 13px;
    }
    .PriceCircle {
        height: 100px;
        width: 100px;
    }
    .PriceCircle p,
    .PriceText p {
        font-size: 13px;
    }
    .PriceCircle p:nth-child(1) {
        font-size: 20px;
    }
    .PriceText h2 {
        font-size: 23px;
    }
    .RightSection li {
        font-size: 13px;
        line-height: 25px;
    }
    .btn {
        font-size: 13px;
    }
    .SelectionDiv {
        gap: 10px;
    }
    .SelectionDiv button {
        padding: 15px 20px;
    }
}
@media only screen and (max-width: 480px) {
    .LeftSection {
        height: 250px;
    }
    .PriceText {
        margin-left: 10px;
    }
    .PriceCircle {
        height: 70px;
        width: 80px;
    }
    .PriceCircle p,
    .PriceText p {
        font-size: 10px;
    }
    .PriceCircle p:nth-child(1) {
        font-size: 15px;
    }
    .Pricing h1 {
        font-size: 35px;
    }
    .PriceText h2 {
        font-size: 18px;
    }
    .RightSection ul {
        padding-inline-start: 20px;
    }
}
@media only screen and (max-width: 340px) {
    .LeftSection {
        height: 200px;
    }
    .SelectionDiv .notActive,
    .SelectionDiv .activePlan {
        font-size: 12px;
    }
    .SelectionDiv button {
        padding: 15px 10px;
    }
}
/* Banner.css */

/* Banner.css */

/* Banner.css */

.imageContainer {
    margin: 10px;
    border-radius: 8px;
    height: 450px;
    background-size: cover;
    border: 2px solid black;
    overflow: hidden; /* overflow: hidden; */
    width: 20vw; /* Adjust the width as needed */
    margin-left: 20px;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* Banner.css */

.marqueeContainer {
    display: flex;
    margin-right: -10px; /* Add a negative margin to eliminate the gap */
}

.marqueeContainer .imageContainer1 {
    margin: 10px;
    margin-top: 20px; /* Adjust the margin-top as needed */
    border: 1px solid #000000;
    border-radius: 8px;
    overflow: hidden;
    width: 20px; /* Adjust the width as needed */
}

.marqueeContainer .imageContainer1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 900px) {
    .imageContainer {
        width: 70vw !important;
    }
}
