.outerDiv {
    /* background-color: var(--footer-bg);
  text-align: center;
  display: flex;
  position: relative;
  margin-top: 10em;
  font-family: var(--primary-font-family); */
    color: white;

    position: relative;
    z-index: 0;
    width: 100%;
    padding: 2em 0 4em 0;
    background-color: #edf2f9;
    justify-content: space-between;
    font-family: var(--primary-font-family);
    overflow: hidden;
}
.outerDiv::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--footer-bg);
    transform-origin: 0 100%; /* Set the origin of the skew transformation */

    z-index: -1;
}

.ScalingContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1680px;
    margin: 0 auto;
    width: 100%;
    position: relative;
}
.heading {
    padding: 3em 3em 0em 3em;
    width: 50%;
    text-align: center;
}
.heading h1 {
    font-size: 45px;
    text-align: center;
    margin: 20px;
    padding: 0;
    text-transform: uppercase;
}
.heading h4 {
    text-align: center;
    background: var(--hap-gradient-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    margin: 20px;
}
.heading p {
    margin: 20px;
}
.images {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}
.images .laptopimg {
    height: 200px;
}
.laptopimg {
    position: absolute;
    bottom: -4%;
    /* left: 10%; */
    left: 8%;
    z-index: 2;
}
.ipadimg {
    height: 230px;
    position: absolute;
    bottom: -8%;
    /* left: 10%; */
    right: 15%;
    z-index: 2;
}

.images .tvdiv {
    height: 490px;
    /* background-image: url("../../img/Home/tv2.png"); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.images .tvdiv img {
    height: 100%;
}
.Video {
    /* margin-top: 20px; */
    height: 360px;
    width: 860px;
    border-radius: 10px;
    position: absolute;
    right: -12.5%;
    top: 11%;
    overflow: 'hidden';
    opacity: 0.7;
}
.videoButton {
    position: absolute;
    right: 5%;
    top: 11%;
    height: 350px;
    width: 650px;
    background-color: rgba(0, 0, 0, 0);
}
.PlayButton {
    position: absolute;
    top: 40%;
    left: 45%;
    font-size: 5em;
    color: rgba(128, 128, 128, 0.804);
}
.PlayButton:hover {
    color: rgba(255, 255, 255, 0.804);
}
.modalVideo {
    width: 790px;
    height: 450px;
}
.StyledButton {
    position: relative;
}
.StyledButton img:nth-child(1) {
    position: absolute;
    top: 0;
    left: -60%;
    width: 110px;
}
.StyledButton img:nth-child(2) {
    position: absolute;
    top: 0;
    left: 120%;
    width: 110px;
}
.btn {
    flex: 1 1 auto;
    margin-bottom: 10px;
    margin-top: 4em;
    padding: 20px 35px;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    transition: 0.5s;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    border: none;
    animation: floatAnimationCurve 4s ease-in-out infinite;
}
@keyframes floatAnimationCurve {
    0%,
    100% {
        transform: translateY(0) scale(1);
    }
    50% {
        transform: translateY(10px) scale(1.2); /* Adjust the horizontal float distance as needed */
    }
}
.btn:hover {
    --x: 100%;
    cursor: pointer;
}
#btn1 {
    --c1: #c600ff;
    --c2: #007bff;
    color: white;
}

@media (width>1430px) {
    .laptopimg {
        left: 11%;
    }

    .ipadimg {
        right: 19%;
    }
}

@media only screen and (max-width: 1000px) {
    .modalVideo {
        width: 650px;
        height: 370px;
    }
    .heading h1 {
        font-size: 40px;
    }
    .heading h4 {
        font-size: 14px;
    }
    .heading p {
        font-size: 12px;
    }
    .StyledButton img:nth-child(1) {
        top: 0;
        left: -60%;
        width: 100px;
    }
    .StyledButton img:nth-child(2) {
        top: 0;
        left: 110%;
        width: 100px;
    }
    .btn {
        font-size: 13px;
        padding: 13px 20px;
    }
    .images .laptopimg {
        bottom: -4%;
        left: 5%;
        height: 170px;
    }
    .images .ipadimg {
        bottom: -8%;
        right: 8%;
        height: 200px;
    }
}
@media only screen and (max-width: 840px) {
    .images .tvdiv {
        height: 385px;
    }
    .modalVideo {
        width: 550px;
        height: 310px;
    }
    .Video {
        height: 290px;
        width: 500px;
        right: 4%;
        top: 11%;
    }
    .heading h1 {
        font-size: 35px;
    }
    .heading h4 {
        font-size: 13px;
    }
    .heading p {
        font-size: 12px;
    }
    .StyledButton img:nth-child(1) {
        top: 0;
        left: -60%;
        width: 90px;
    }
    .StyledButton img:nth-child(2) {
        top: 0;
        left: 110%;
        width: 90px;
    }
    .images .laptopimg {
        bottom: -4%;
        left: 5%;
        height: 140px;
    }
    .images .ipadimg {
        bottom: -8%;
        right: 8%;
        height: 170px;
    }
}
@media only screen and (max-width: 730px) {
    .images .tvdiv {
        height: 350px;
    }
    .Video {
        height: 270px;
        width: 450px;
        right: 4%;
        top: 9%;
    }
    .heading h1 {
        font-size: 30px;
    }

    .StyledButton img:nth-child(1) {
        top: 0;
        left: -60%;
        width: 80px;
    }
    .StyledButton img:nth-child(2) {
        top: 0;
        left: 110%;
        width: 80px;
    }
    .images .laptopimg {
        bottom: -4%;
        left: 5%;
        height: 130px;
    }
    .images .ipadimg {
        bottom: -8%;
        right: 8%;
        height: 160px;
    }
    .PlayButton {
        left: 40%;
    }
    .modalVideo {
        width: 480px;
        height: 270px;
    }
}
@media only screen and (max-width: 630px) {
    .modalVideo {
        width: 460px;
        height: 260px;
    }

    .heading h1 {
        font-size: 25px;
    }
    .images .tvdiv {
        height: 340px;
    }
    .Video {
        height: 270px;
        width: 440px;
        right: 4%;
        top: 9%;
    }
    .StyledButton img:nth-child(1) {
        top: 0;
        left: -60%;
        width: 80px;
    }
    .StyledButton img:nth-child(2) {
        top: 0;
        left: 110%;
        width: 80px;
    }
    .images .laptopimg {
        bottom: -4%;
        left: 2%;
        height: 120px;
    }
    .images .ipadimg {
        bottom: -8%;
        right: 4%;
        height: 150px;
    }
    .PlayButton {
        top: 30%;
        left: 40%;
        font-size: 5em;
    }
}
@media only screen and (max-width: 580px) {
    .modalVideo {
        width: 400px;
        height: 225px;
    }
    /* .images .tvdiv {
    height: 290px;
  } */

    .images .tvdiv {
        height: 290px;
    }
    .Video {
        height: 210px;
        width: 395px;
        right: 1.5%;
        top: 11%;
    }
    /* .Video {
    height: 210px;
    width: 460px;
    right: -6%;
    top: 9%;
  } */
    .StyledButton img:nth-child(1) {
        top: 0;
        left: -45%;
        width: 70px;
    }
    .StyledButton img:nth-child(2) {
        top: 0;
        left: 110%;
        width: 70px;
    }
    .images .laptopimg {
        bottom: -4%;
        left: 2%;
        height: 100px;
    }
    .images .ipadimg {
        bottom: -8%;
        right: 4%;
        height: 130px;
    }
}
@media only screen and (max-width: 500px) {
    .images .tvdiv {
        height: 250px;
    }

    .Video {
        height: 180px;
        width: 340px;
        right: 1.5%;
        top: 11%;
    }

    .StyledButton img:nth-child(1) {
        top: 0;
        left: -45%;
        width: 60px;
    }
    .StyledButton img:nth-child(2) {
        top: 0;
        left: 110%;
        width: 60px;
    }
    .images .laptopimg {
        bottom: -4%;
        left: 3%;
        height: 90px;
    }
    .images .ipadimg {
        bottom: -8%;
        right: 7%;
        height: 110px;
    }
    .btn {
        font-size: 10px;
    }
    .PlayButton {
        top: 30%;
        left: 40%;
        font-size: 4.5em;
    }
}
@media only screen and (max-width: 450px) {
    .modalVideo {
        width: 330px;
        height: 185px;
    }
    .images .tvdiv {
        height: 220px;
    }
    .heading {
        width: 70%;
    }
    .Video {
        height: 160px;
        width: 300px;
        right: 1.5%;
        top: 11%;
    }

    .StyledButton img:nth-child(1) {
        top: 0;
        left: -40%;
        width: 50px;
    }
    .StyledButton img:nth-child(2) {
        top: 0;
        left: 110%;
        width: 50px;
    }
    .images .laptopimg {
        bottom: -4%;
        left: 3%;
        height: 80px;
    }
    .images .ipadimg {
        bottom: -8%;
        right: 7%;
        height: 100px;
    }
    .btn {
        font-size: 10px;
        padding: 10px 15px;
    }
}
@media only screen and (max-width: 400px) {
    .modalVideo {
        width: 330px;
        height: 190px;
    }
    .images .tvdiv {
        height: 180px;
    }
    .heading {
        width: 70%;
    }
    .Video {
        height: 130px;
        width: 300px;
        right: -9%;
        top: 11%;
    }
    /* .Video {
    height: 130px;
    width: 300px;
    right: -9%;
    top: 9%;
  } */
    .StyledButton img:nth-child(1) {
        top: 0;
        left: -40%;
        width: 50px;
    }
    .StyledButton img:nth-child(2) {
        top: 0;
        left: 110%;
        width: 50px;
    }
    .images .laptopimg {
        bottom: -4%;
        left: 3%;
        height: 70px;
    }
    .images .ipadimg {
        bottom: -8%;
        right: 7%;
        height: 80px;
    }
    .btn {
        font-size: 10px;
        padding: 10px 15px;
    }
    .PlayButton {
        top: 30%;
        left: 40%;
        font-size: 3.5em;
    }
}
@media only screen and (max-width: 360px) {
    .modalVideo {
        width: 260px;
        height: 150px;
    }
    .images .tvdiv {
        height: 180px;
    }
    .heading {
        width: 90%;
    }
    .Video {
        height: 130px;
        width: 300px;
        right: -9%;
        top: 12%;
    }

    .images .laptopimg,
    .images .ipadimg,
    .StyledButton img {
        display: none;
    }
    .btn {
        font-size: 12px;
        padding: 10px 15px;
        margin-top: 0;
    }
}

@media (width< 992px) {
    .heading {
        width: 70%;
    }
}

@media (width<768px) {
    .heading {
        padding: 1rem 1rem 0 1rem;
        width: 90%;
    }
}
