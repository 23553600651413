@font-face {
    font-family: 'Futura Pt Cond';
    src: url('https://uploads-ssl.webflow.com/6608172cae06109256985a54/6608172dae06109256985b3e_FuturaPTCond-ExtraBold.ttf')
        format('truetype');
}

.body {
    background-color: rgb(246, 244, 247);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.HeroSection {
    /* height: 140vh; */
    background-size: cover;
    background-position: center;
    color: white;
    width: 100%;
    font-family: var(--primary-font-family);
    position: relative;
    /* padding-top: 15em; */
    display: flex;
}

.heroImage {
    width: 100%;
    height: 115vh;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.HeroSection .container {
    display: flex;
    width: 100%;
    height: 115vh;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
}

.heroOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 115vh;
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 60%
    );
    z-index: 1;
}

.heroSuccess {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid #007bff8b;
    background-color: #30669f;
    border-radius: 104px;
    align-items: center;
    padding: 8px 14px 8px 8px;
    transition: scale 0.3s ease-in-out;
}

.heroSuccess:hover {
    scale: 0.93;
}

.heroIcon {
    font-size: 12px;
}

.chipsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.heroButton {
    background-color: var(--c2);
    border: none;
    margin-top: 1.5rem;
    border-radius: 10px;
    text-transform: none;
    font-family: var(--secondary-font-family);
    transition: all 0.3s ease-out;
    padding: 15px 20px;
}

.chipsImage {
    border: 2px solid #30669f;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    object-fit: cover;
}

.chipsImageOff {
    margin-left: -12px;
}

.heroButton:hover {
    background-color: var(--c1);
}

.heroSuccess p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
}

.heroContent {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heroTitle {
    font-family: Futura Pt Cond, sans-serif;
    font-size: 63px;
    text-align: center;
    margin: 1rem 0;
    color: white;
    line-height: 92%;
    padding: 0 1rem;
    text-transform: uppercase;
}

.heroButtons {
    display: flex;
    gap: 1rem;
}

.heroPara {
    max-width: 630px;
    text-align: center;
    font-size: 18px;
}

.leadingBrands {
    margin: 5px 0 0 !important;
    font-size: 19px;
    letter-spacing: 10px;
}

.LeftSection {
    width: 50%;
    padding: 0 0 0 3.5em;
    position: relative;
}
.LeftSection p:nth-child(1) {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    text-transform: uppercase;
}

.Flipper {
    display: block;
    font-size: 90px;
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-weight: 500;
}
.LeftSection h1 {
    font-size: 80px;
    /* text-transform: capitalize; */
    margin: 0;
}
.para {
    font-size: 18px;
}
.btn {
    flex: 1 1 auto;
    margin: 10px;
    padding: 20px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    border: none;
    display: inline-block;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    font-size: 15px;
    font-weight: 600;
}
.btn:hover {
    --x: 100%;
    cursor: pointer;
}
#btn1 {
    --c1: #c600ff;
    --c2: #007bff;
    color: white;
}
.btnSimple {
    display: inline-block;
    background: transparent;
    color: white;
    border: none;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    gap: 7px;
}
.btnSimple div {
    display: flex;
    align-items: center;
}
.btnSimple div div {
    display: inline-block;
    margin-left: 10px;
    transition: all 1s ease;
    transform: rotate(-45deg);
}
.btnSimple:hover div div {
    transform: rotate(0deg);
}
.btnSimple:hover div {
    text-decoration: underline;
    text-underline-offset: 7px;
}
.RightSection {
    width: 50%;
    display: flex;

    gap: 0px;
    position: relative;
}
.button .RightSection {
    width: 50%;
}
.image1 {
    margin-top: 3em;
    width: 95%;
}
.image1 img,
.image2 img {
    width: 100%;
}
.image2 {
    /* border-radius: 200px; */
    width: 95%;
    /* height: 520px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  background-image: url("../../img/Home/homebanner3.jpg"); */
}

.spandiv {
    font-size: 80px;
    margin: 0;
    cursor: pointer;
    display: inline-block;
}

@keyframes rotateImage {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.floatingImage {
    position: absolute;
    left: 35%;
    top: 40%;
    z-index: 2;
    animation: rotateImage 10s linear infinite;
}
.Laravel {
    position: absolute;
    bottom: 10%;
    width: 150px;
    left: 0;
    z-index: 2;
    animation: floatAnimation 3s ease-in-out infinite;
}
.React1 {
    position: absolute;
    width: 150px;
    z-index: 2;
    bottom: 18%;
    left: 30%;
    animation: floatAnimation 5s ease-in-out infinite;
}
.Figma {
    position: absolute;
    z-index: 2;
    bottom: 25%;
    width: 120px;
    animation: floatAnimation 2s ease-in-out infinite;
}

@keyframes floatAnimation {
    0%,
    100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(
            20px
        ); /* Adjust the horizontal float distance as needed */
    }
}

.LeftSection .Laravel1,
.LeftSection .React2,
.LeftSection .Figma1,
.LeftSection .floatingImage1 {
    display: none;
}
.floatingImage1 {
    position: absolute;
    z-index: 2;
    animation: rotateImage 10s linear infinite;
}
.Laravel1 {
    position: absolute;
    top: 0;
    width: 150px;
    left: 0;
    z-index: 2;
    animation: floatAnimation 3s ease-in-out infinite;
}
.React2 {
    position: absolute;
    width: 150px;
    z-index: 2;
    top: 0;
    left: 0%;
    animation: floatAnimation 5s ease-in-out infinite;
}
.Figma1 {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 120px;
    animation: floatAnimation 2s ease-in-out infinite;
}
/* position: relative;
width: 150px;
height: fit-content;
margin-top: 70%;
margin-right: -25%; */

.Brands {
    display: flex;
    /* margin-right: 30px;
  margin-left: 30px; */
    margin-top: -9%;
    background-color: white;
    border-radius: 120px;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    width: 90%;
    font-family: var(--primary-font-family);
}
.Brandstitle {
    width: 20%;
    text-align: left;
    margin-left: 5%;
}
.Brandstitle p {
    margin: 0;
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
}
.Brandstitle h1 {
    margin-top: 0;
}
.Brandsimages {
    width: 80%;
}
.gridContainer {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns with equal width */
    grid-template-rows: repeat(2, 1fr); /* 2 rows with equal height */
    gap: 1px; /* Gap between grid items (adjust as needed) */
}
.gridItem {
    background-color: white;
    text-align: center;
    width: 150px;
    height: 100px;
    border: none;
    display: flex;
    align-items: center;
    padding: 10px;
}
.gridItem img {
    width: 100%;
    object-fit: contain;
    height: inherit;
    filter: grayscale(100%);
    opacity: 0.7;
    transition: all 1s ease;
}
.gridItem img:hover {
    filter: grayscale(0%);
    transform: scale(1.2);
}

.heroLogo {
    display: none;
}
/*  */
@media only screen and (max-width: 1250px) {
    /* .HeroSection {
    height: 130vh;
  } */
    .LeftSection h1 {
        font-size: 60px;
    }
    .LeftSection div span {
        font-size: 60px;
        width: fit-content;
    }
    .LeftSection p {
        font-size: 15px;
    }
    .image1 img,
    .image2 img {
        width: 90%;
    }
    .floatingImage img,
    .floatingImage1 img {
        width: 150px;
    }
    .React1,
    .React2 {
        width: 120px;
    }
    .Figma,
    .Figma1 {
        width: 120px;
    }
    .Laravel,
    .Laravel1 {
        width: 120px;
    }

    .Brandstitle h1 {
        font-size: 25px;
    }
    .gridItem {
        width: 85%;
    }
}
@media only screen and (max-width: 1100px) {
    /* .HeroSection {
    height: 120vh;
  } */
    .LeftSection h1 {
        font-size: 60px;
    }
    .LeftSection div span {
        font-size: 60px;
    }
    .LeftSection p {
        font-size: 15px;
    }
    .image1 img,
    .image2 img {
        width: 90%;
    }
    .floatingImage img,
    .floatingImage1 img {
        width: 120px;
    }
    .React1,
    .React2 {
        width: 110px;
    }
    .Figma,
    .Figma1 {
        width: 100px;
    }
    .Laravel,
    .Laravel1 {
        width: 110px;
    }
    .Brandstitle p {
        font-size: 15px;
    }
    .Brandstitle h1 {
        font-size: 23px;
    }
    .gridItem {
        width: 70%;
    }
}
@media only screen and (max-width: 1000px) {
    .Brands {
        display: none;
    }
    .Brandstitle {
        /* margin-right: 25px; */
        /* display: none; */
        width: 100%;
        text-align: center;
    }
    .Brandstitle p {
        /* display: none; */
        margin: 5px 0 0 0;
    }
    .Brandstitle h1 {
        width: 100%;
        margin: 5px 0 0 0;
    }
    .Brands .gridContainer {
        margin-left: 25px;
    }
}
@media only screen and (max-width: 970px) {
    .LeftSection h1 {
        font-size: 50px;
    }
    .LeftSection div span {
        font-size: 50px;
    }
    .LeftSection p {
        font-size: 13px;
    }
    .image1,
    .image2 {
        width: 100%;
    }
    .floatingImage img,
    .floatingImage1 img {
        width: 120px;
    }
    .React1,
    .React2 {
        width: 110px;
    }
    .Figma,
    .Figma1 {
        width: 100px;
    }
    .Laravel,
    .Laravel1 {
        width: 110px;
    }
    .Brands {
        margin-top: -15%;
    }
    .Brandstitle p {
        font-size: 15px;
    }
    .Brandstitle h1 {
        font-size: 23px;
    }
    .gridItem {
        width: 60%;
    }
    .gridItem img {
        transform: scale(1.3);
    }
    .gridItem img:hover {
        transform: scale(1.5);
    }
}
@media only screen and (max-width: 925px) {
    .container {
        margin-top: 0em;
        justify-content: center;
    }
    .LeftSection h1 {
        font-size: 50px;
    }
    .LeftSection div span {
        font-size: 50px;
    }
    .LeftSection p {
        font-size: 13px;
    }
    .image1,
    .image2 {
        width: 100%;
    }
    .floatingImage img,
    .floatingImage1 img {
        width: 120px;
    }
    .React1,
    .React2 {
        width: 100px;
        top: 75%;
    }
    .Figma,
    .Figma1 {
        width: 100px;
        top: 65%;
    }
    .Laravel,
    .Laravel1 {
        width: 100px;
        top: 85%;
    }
    .btn {
        font-size: 10px;
        padding: 10px 15px;
    }
    .btnSimple {
        font-size: 10px;
    }
}
@media only screen and (max-width: 850px) {
    .floatingImage img,
    .floatingImage1 img {
        width: 110px;
    }
    .React1,
    .React2 {
        width: 90px;
        top: 90%;
    }
    .Figma,
    .Figma1 {
        width: 90px;
        top: 75%;
    }
    .Laravel,
    .Laravel1 {
        width: 90px;
        top: 100%;
    }
    .image1,
    .image2 {
        width: 90%;
    }

    .Brands {
        margin-top: -12%;
        border-radius: 110px;
    }
    .Brandstitle p {
        font-size: 15px;
    }
    .Brandstitle h1 {
        font-size: 23px;
    }
    .gridItem {
        width: 60%;
    }
    .gridItem img {
        transform: scale(1.3);
    }
    .gridItem img:hover {
        transform: scale(1.5);
    }
}
@media only screen and (max-width: 770px) {
    .RightSection {
        display: none;
    }
    .LeftSection {
        width: 100%;
    }
    .LeftSection .Laravel1,
    .LeftSection .React2,
    .LeftSection .Figma1,
    .LeftSection .floatingImage1 {
        display: initial;
    }
    .LeftSection .Laravel1 {
        left: 58%;
        top: 28%;
    }
    .LeftSection .React2 {
        top: 20%;
        left: 70%;
    }
    .LeftSection .Figma1 {
        top: 20%;
        left: 45%;
        width: 85px;
    }
    .LeftSection .floatingImage1 {
        top: -6%;
        left: 75%;
    }
    .LeftSection .floatingImage img {
        width: 90px;
    }
}
@media only screen and (max-width: 650px) {
    .RightSection {
        display: none;
    }
    .LeftSection {
        width: 90%;
        padding: 0;
    }
    .container {
        justify-content: center;
    }
    .LeftSection .Laravel1,
    .LeftSection .React2,
    .LeftSection .Figma1,
    .LeftSection .floatingImage1 {
        display: initial;
    }
    .LeftSection .Laravel1 {
        left: 58%;
        top: 28%;
        width: 80px;
    }
    .LeftSection .React2 {
        top: 20%;
        left: 70%;
        width: 75px;
    }
    .LeftSection .Figma1 {
        top: 18%;
        left: 50%;
        width: 75px;
    }
    .LeftSection .floatingImage1 {
        top: 44%;
        left: 75%;
    }
    .LeftSection .floatingImage img {
        width: 100px;
    }
    .LeftSection h1 {
        font-size: 40px;
    }
    .LeftSection h4 {
        font-size: 15px;
    }
    .LeftSection p {
        font-size: 13px;
    }
    .LeftSection div span {
        font-size: 40px;
    }
}
@media only screen and (max-width: 540px) {
    .LeftSection .floatingImage1 img {
        top: 30%;
        left: 75%;
        width: 85px;
    }
}
@media only screen and (max-width: 465px) {
    .LeftSection .floatingImage1 {
        top: 18%;
        left: 75%;
    }
    .LeftSection .floatingImage img {
        width: 90px;
    }
    .LeftSection .Laravel1 {
        display: none;
    }
    .LeftSection .React2 {
        display: none;
    }
    .LeftSection .Figma1 {
        display: none;
    }
}
@media only screen and (max-width: 380px) {
    .LeftSection .floatingImage1 {
        top: 15%;
        left: 70%;
    }
    .LeftSection .floatingImage img {
        width: 80px;
    }
    .LeftSection h1 {
        font-size: 35px;
    }
    .LeftSection h4 {
        font-size: 15px;
    }
    .LeftSection p {
        font-size: 10px;
    }
    .LeftSection div span {
        font-size: 35px;
    }
}
@media only screen and (max-width: 340px) {
    .LeftSection .floatingImage1 {
        top: 16%;
        left: 70%;
    }
    .LeftSection .floatingImage1 img {
        width: 50px;
    }
    .LeftSection h1 {
        font-size: 30px;
    }
    .LeftSection h4 {
        font-size: 15px;
    }
    .LeftSection p {
        font-size: 8px;
    }
    .LeftSection div span {
        font-size: 30px;
    }
    .btn {
        font-size: 8px;
    }
    .btnSimple {
        font-size: 9px;
        padding: 5px;
    }
}

@media (width<1200px) and (height>1000px) {
    .HeroSection .container,
    .heroOverlay,
    .heroImage {
        height: 75vh;
    }

    .heroContent {
        width: 62%;
    }
}

@media (width<992px) {
    .HeroSection .container,
    .heroOverlay,
    .heroImage {
        height: 100vh;
    }

    .heroContent {
        width: 74%;
    }

    .heroLogo .logo img {
        width: 240px;
        object-fit: cover;
    }

    .heroLogo {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .nav__circle {
        background-color: var(--hap-base-color);
        border-radius: 50%;
        width: 10px;
        height: 10px;
    }

    .nav__empty {
        width: 150px;
    }

    .strategy__container {
        display: flex;
        align-items: center;
        color: white;
        gap: 0.5rem;
        position: relative;
        margin-bottom: 1rem;
        padding: 0.5rem 0;
    }

    .strategy__text {
        width: fit-content;
        font-size: 1.2rem;
        font-weight: 600;
        position: absolute;
        top: 18%;
        right: 6%;
    }

    .strategy__wrapper {
        position: relative;
        overflow: hidden;
    }

    .strategy__heading {
        font-size: 1.2rem;
        font-weight: 600;
        padding-left: 1rem;
        margin: 0;
    }
}

.heroSuccess {
    margin-bottom: 1rem;
}

@media (width<768px) {
    .heroTitle {
        font-size: 48px;
        padding: 0;
        line-height: 120%;
    }

    .heroContent {
        width: 93%;
    }

    .leadingBrands {
        text-align: center;
        letter-spacing: 2px;
    }

    .HeroSection .container,
    .heroOverlay,
    .heroImage {
        height: 110vh;
    }
}

@media (width<400px) and (height<700px) {
    .HeroSection .container,
    .heroOverlay,
    .heroImage {
        height: 110vh;
    }

    .heroLogo .logo img {
        width: 200px;
        object-fit: cover;
    }
}
