.outerDiv {
  /* background-image: url("../img/Background/Component\ 17.png");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat; */
  /* background: rgb(0, 0, 0);
  background: linear-gradient(
    97deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(13, 13, 14, 1) 29%,
    rgba(49, 52, 55, 1) 78%,
    rgba(84, 89, 95, 1) 100%
  ); */
  background: rgb(30, 30, 30);
  background: linear-gradient(
    97deg,
    rgba(30, 30, 30, 1) 25%,
    rgba(39, 40, 41, 1) 53%,
    rgba(56, 58, 61, 1) 83%,
    rgba(84, 89, 95, 1) 100%
  );
  /* height: 250px; */
  padding: 2em 0;
  font-family: var(--primary-font-family);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
.text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 1em;
}
.leftdiv {
  width: fit-content;
}
.leftdiv h4 {
  font-size: 25px;
  color: white;
}
.leftdiv p {
  color: rgb(152, 152, 152);
  font-size: 16px;
}
.middlediv {
  width: fit-content;
}
.middlediv div h1 {
  color: white;
  display: inline;
}
.middlediv span {
  color: #007bff;
}
.rightdiv {
  width: fit-content;
}
.rightdiv button {
  padding: 12px 15px;
  background-color: #007bff;
  border: none;
  color: white;
  letter-spacing: 1.5px;
  margin: 0;
  text-transform: capitalize;
}
button {
  border: 1px solid white;
  padding: 20px 25px;
  border-radius: 30px;
  background-color: transparent;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}
.leftdiv p {
  margin: 0;
}
.middlediv div .iconicon {
  height: 70px;
  top: 0;
}

.middlediv .icon {
  height: 20px;
}
@media only screen and (max-width: 1020px) {
  .outerDiv .leftdiv p {
    font-size: 18px;
  }
  .rightdiv button {
    font-size: 15px;
  }
}
@media only screen and (max-width: 950px) {
  .leftdiv p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 890px) {
  .outerDiv {
    flex-direction: column;
  }
  .rightdiv {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rightdiv button {
    width: 100%;
  }
}
@media only screen and (max-width: 750px) {
  .leftdiv {
    display: none;
  }
  .middlediv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: whitesmoke;
  }
  .middlediv .icon {
    font-size: 70px;
  }
  .middlediv h1 svg {
    display: none;
  }
  .middlediv div:nth-child(2) {
    margin-top: 2em;
  }
}
@media only screen and (max-width: 600px) {
  .middlediv h1 {
    font-size: 30px;
    text-align: center;
    margin: 0;
  }
  .middlediv div:nth-child(2) {
    flex-direction: column;
    margin-top: 3.5em;
  }
}
@media only screen and (max-width: 450px) {
  .middlediv h1 {
    font-size: 25px;
    text-align: center;
  }
}
