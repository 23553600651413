/* Search Button Start */
:root {
    --c1: #c600ff;
    --c2: #007bff;
}
.search-domain {
    margin-top: 50px;
}

.search-domain h3 {
    font-weight: 600;
    margin-bottom: 20px;
}
.general-input {
    position: relative;
    width: 80%;
    display: flex;
}
.fill-input {
    width: 90%;
    font-size: 13px;
    font-family: open sans;
    color: #000;
    border: solid 1px #efefef;
    border-radius: 50px;
    padding: 15px 20px 15px 25px;
    background-color: #fff;
    outline: 0;
    box-shadow: 0 2px 8px 0 rgba(62, 62, 79, 0.23);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.fill-input::placeholder {
    opacity: 1; /* Firefox */
    font-family: var(--primary-font-family);
}

.fill-input::-ms-input-placeholder {
    /* Edge 12-18 */
    font-family: var(--primary-font-family);
}

.general-input input.btn {
    border: none;
    right: 0;
    position: absolute;
}
.btn-default-yellow-fill {
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    color: #fff !important;
}

.btn {
    cursor: pointer !important;
    border: 1px solid gray;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    margin: 0;
    padding: 17px 26px;
    position: relative;
    background-color: transparent;
    letter-spacing: 0.02em;
    box-shadow: 0 2px 8px 0 rgba(62, 62, 79, 0.23);
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}
.btn-default-yellow-fill:hover {
    background-color: gray !important;
    color: #fff !important;
}
.btn:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
    --x: 100%;
    cursor: pointer;
}
/* Responsive */
@media (max-width: 767px) {
    .fill-input {
        font-size: 11px !important;
        padding: 15px 20px !important;
        width: 85% !important;
    }
    .general-input input.btn {
        padding: 17px;
        right: -5px;
    }
}
/* Search Button End */

.domain-prices {
    margin-top: 10px;
    margin-bottom: 20px;
}

.domain-prices ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.domain-prices li {
    display: inline-flex;
    padding: 10px 10px;
    position: relative;
    font-size: 18px;
    color: #007bff;
}

.domain-prices li sup {
    font-size: 14px;
    top: -4px;
}

.domain-prices li .price {
    color: #fff !important;
    margin-left: 10px;
}
