.outerDiv {
    color: white;
    background: var(--hap-gradient-color);
    padding: 4em 0 4.5em 0;
    font-family: var(--primary-font-family);
    position: relative;
}
.main_container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px;
}

.strategyContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: start;
}

.strategyImage {
    height: 370px;
    object-fit: cover;
}

.strategyPara {
    text-align: start;
}

.strategyHeading {
    font-size: 35px;
    text-align: left;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}
.centerContent {
    margin-top: 10px;
}

.figure {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
}

.figContent {
    font-size: 14px;
}

.bookDetails {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
}

.svgIcon {
    font-size: 18px;
}

.strategyForm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    gap: 1rem;
    margin-top: 10px;
}

.strategyForm div {
    padding: 4% 4.5%;
    border-radius: 50px;
    font-size: 18px;
    outline: none;
    background-color: white;
    display: flex;
    gap: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
}

.strategyForm img {
    height: 30px;
}

.strategyForm input {
    width: 100%;
    border: none;
    background-color: transparent;
    font-family: var(--primary-font-family);
}

.strategyForm input:focus {
    outline: none;
}

.leftBookFigure {
    margin-left: 0;
}

.btn {
    flex: 1 1 auto;
    margin: 10px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    border: none;
    display: inline-block;
    background-color: rgba(1, 1, 1, 0.579);
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    font-size: 15px;
    font-weight: 600;
    margin: 0 !important;
    border: 3px solid transparent;
}

.btn:hover {
    border: 3px solid white;
    background-color: transparent;
}

.mainPopup {
    position: fixed;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.384);
    width: 100%;
    height: 100vh;
    inset: 0;
    z-index: 1000;
}

.popup {
    width: 300px;
    background-color: rgb(255, 255, 255);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    padding: 2rem;
    font-size: 1.2rem;
    position: relative;
    z-index: 1001;
    color: black;
    font-family: var(--primary-font-family);
    border-radius: 10px;
}
.popup p {
    margin: 0;
    text-align: center;
}

.popup svg {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
}

.star {
    height: 50px;
    position: absolute;
    right: 80px;
}

.pendingGif {
    height: 200px;
}

.btnDiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.closeBtn {
    text-align: center;
    transition: all 0.25s ease-in-out;
    text-transform: capitalize !important;
    padding: 10px 30px;
    border: none;
    display: inline-block;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    font-size: 15px;
    font-weight: 600;
    margin: 0 !important;
    background: var(--hap-gradient-color);
}

.closeBtn:hover {
    background: black;
    color: white;
}

@media (max-width: 1025px) {
    .strategyImage {
        height: 300px;
    }

    .strategyHeading {
        font-size: 32px;
    }
}

@media (max-width: 900px) {
    .strategyContainer {
        grid-template-columns: 1fr;
    }

    .centerContent {
        padding: 0 1.5rem 0 1.5rem;
    }

    .strategyImage {
        height: 450px;
    }
    .strategyForm {
        margin-top: 2rem;
    }
    .strategyForm div {
        padding: 3% 3.5%;
    }
}

@media (max-width: 500px) {
    .strategyImage {
        height: 300px;
    }

    .centerContent {
        padding-right: 1rem;
    }
}

@media (max-width: 500px) {
    .main_container {
        padding: 0%;
    }

    .strategyContainer {
        padding: 0.5rem;
    }
    .strategyHeading {
        font-size: 25px;
    }

    .strategyImage {
        width: 300px;
    }

    .leftBookFigure {
        width: 260px;
        margin: 0;
    }

    .strategyForm div {
        padding: 2% 2.5%;
        border-radius: 50px;
        font-size: 16px;
        gap: 0.6rem;
    }

    .pendingGif {
        height: 190px !important;
        width: 190px !important;
    }

    .pendingGif svg {
        width: 180px !important;
        height: 180px !important;
    }

    .mainPopup {
        width: 100vw;
        height: 100%;
    }

    .pendingGif div {
        height: fit-content !important;
        width: fit-content !important;
    }

    .popup {
        width: 200px;
    }
}
