.body {
    background-color: rgb(246, 244, 247);

    font-family: var(--primary-font-family);
}

.LeftSection {
    position: relative;
    display: flex;
    justify-content: center;
}

.LeftSection div img {
    position: relative;
    z-index: 1;
}

.AboutSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.AboutVideo {
    position: relative;
    display: flex;
}

.AboutVideo video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.AboutVideoOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
}

.AboutVideoTag {
    height: 100%;
}

.AboutPlayButton {
    position: absolute;
    top: 40%;
    cursor: pointer;
    left: 45%;
    font-size: 5em;
    color: #3195ffc7;
    transition: all 0.2s ease-in-out;
}

.AboutPlayButton:hover {
    color: rgba(255, 255, 255, 0.644);
}

.experienceDiv {
    position: absolute;
    background: var(--hap-gradient-color-reverse);
    width: 110px;
    padding: 20px;
    font-size: 20px;
    color: white;
    border-radius: 30px;
    bottom: -5%;
    right: 5%;
    z-index: 1;
}
.experienceDiv span {
    font-size: 50px;
    font-weight: 700;
}
.experienceDiv p {
    margin: 0;
}
.CurveDiv {
    position: absolute;
    right: 5%;
    top: 2%;
    animation: floatAnimationCurve 4s ease-in-out infinite;
}

@keyframes floatAnimationCurve {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(
            20px
        ); /* Adjust the horizontal float distance as needed */
    }
}
.LineDiv {
    position: absolute;
    left: -7%;
    top: 5%;
    z-index: 0;
    animation: floatAnimationLine 4s ease-in-out infinite;
}
@keyframes floatAnimationLine {
    0%,
    100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(
            20px
        ); /* Adjust the horizontal float distance as needed */
    }
}
/* .RightSection h4 {
  transform: rotateX(0deg);
  animation: floatAnimationh4 3s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
}

@keyframes floatAnimationh4 {
  0% {
    transform: rotateX(80deg) translateY(50px);
  }
  100% {
    transform: rotateX(0deg) translateY(0px);
  }
} */
.RightSection {
    padding: 40px 30px 40px 40px;
    max-width: 800px;
}
.RightSection h4 {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    font-size: 1.3rem;
}
.RightSection h2 {
    margin: 10px auto;
    font-size: 42px;
}
.RightSection p {
    font-size: 15px;
    text-align: justify;
    line-height: 1.7em;
}

.aboutTitle {
    display: flex;
    gap: 0.5rem;
}

.aboutTitle img {
    height: 50px;
    width: 50px;
}
.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 5px;
}
.gridItem {
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    display: flex;
    align-items: center;
    padding: 25px 15px;
    gap: 10px;
}
.gridItem .text p {
    text-align: left;
}
.icons {
    width: 20%;
}
.icons img {
    width: 100%;
    animation: floatAnimation 3s ease-in-out infinite;
}
@keyframes floatAnimation {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(
            5px
        ); /* Adjust the horizontal float distance as needed */
    }
}
.text {
    width: 70%;
    animation: floatAnimation 3s ease-in-out infinite;
}
.text p {
    font-size: 18px;
    font-weight: 500;
}
.btn {
    padding: 15px 26px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    border: none;
    display: inline-block;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    font-size: 15px;
    font-weight: 600;
}
.btn:hover {
    --x: 100%;
    cursor: pointer;
}
#btn1 {
    --c1: #c600ff;
    --c2: #007bff;
    color: white;
}

.bottomConfettie {
    display: none;
}
@media only screen and (max-width: 1300px) {
    /* .LeftSection {
    width: 45%;
    position: relative;
  } */
    .LeftSection div img {
        position: relative;
        z-index: 1;
        width: 80%;
    }
    .experienceDiv {
        width: 100px;
        padding: 20px;
        font-size: 15px;
        right: 8%;
    }
    .experienceDiv span {
        font-size: 40px;
        font-weight: 700;
    }
    .experienceDiv p {
        font-size: 15px;
    }
    .RightSection h4 {
        font-size: 15px;
    }
    .RightSection h1 {
        font-size: 65px;
    }
    .RightSection p {
        font-size: 14px;
        text-align: justify;
        line-height: 1.7em;
    }
    .icons img {
        width: 85%;
    }
}
@media only screen and (max-width: 1050px) {
    .LeftSection div img {
        position: relative;
        z-index: 1;
        width: 80%;
    }
    .experienceDiv {
        width: 90px;
        padding: 15px;
        font-size: 15px;
        right: 8%;
    }
    .experienceDiv span {
        font-size: 35px;
    }
    .experienceDiv p {
        font-size: 13px;
    }
    .RightSection h4 {
        font-size: 14px;
    }
    .RightSection h1 {
        font-size: 55px;
    }
    .RightSection p {
        font-size: 12px;
    }
    .gridItem {
        padding: 15px;
    }
    .icons img {
        width: 85%;
    }
    .btn {
        font-size: 13px;
        padding: 13px 20px;
    }
}
@media only screen and (max-width: 915px) {
    .LeftSection div img {
        position: relative;
        z-index: 1;
        width: 85%;
    }
    .experienceDiv {
        width: 80px;
        padding: 13px;
        font-size: 13px;
        right: 8%;
    }
    .experienceDiv span {
        font-size: 30px;
    }
    .experienceDiv p {
        font-size: 12px;
    }
    .RightSection h4 {
        font-size: 13px;
    }
    .RightSection h1 {
        font-size: 50px;
    }
    .RightSection p {
        font-size: 11px;
    }
    .CurveDiv {
        right: 5%;
        top: -2%;
    }
    .LineDiv img {
        height: 450px;
    }
    .gridItem {
        padding: 13px;
        gap: 0;
    }
    .icons img {
        width: 80%;
    }
}
@media only screen and (max-width: 840px) {
    .gridItem {
        padding: 10px;
        gap: 0;
    }
    .experienceDiv {
        width: 70px;
        padding: 13px;
        right: 8%;
    }
    .experienceDiv span {
        font-size: 25px;
    }
    .experienceDiv p {
        font-size: 11px;
    }
    .LineDiv img {
        height: 400px;
    }
    .CurveDiv img {
        height: 80px;
    }
}
@media only screen and (max-width: 810px) {
    .experienceDiv {
        width: 70px;
        padding: 13px;
        right: 8%;
    }
    .experienceDiv span {
        font-size: 25px;
    }
    .experienceDiv p {
        font-size: 11px;
    }
    .LineDiv img {
        height: 400px;
    }
    .CurveDiv img {
        height: 80px;
    }
    .gridItem {
        padding: 10px 7px;
        gap: 0;
    }
    .RightSection h4 {
        font-size: 14px;
    }
    .RightSection h1 {
        font-size: 50px;
    }
    .RightSection p {
        font-size: 10px;
    }
}
@media only screen and (max-width: 750px) {
    .experienceDiv {
        width: 60px;
        padding: 13px;
        right: 8%;
    }
    .gridItem {
        padding: 10px 7px;
        gap: 0;
    }
    .RightSection h4 {
        font-size: 12px;
    }
    .RightSection h1 {
        font-size: 45px;
        margin: 0;
    }
}
@media only screen and (max-width: 730px) {
    .LeftSection {
        display: none;
    }
    .RightSection {
        width: 80%;
    }
    .gridItem {
        padding: 10px 7px;
        gap: 0;
    }
    .RightSection h4 {
        font-size: 13px;
    }
    .RightSection h1 {
        font-size: 50px;
        margin: 0;
    }
    .RightSection p {
        font-size: 12px;
    }
}
@media only screen and (max-width: 530px) {
    .gridItem {
        padding: 5px 7px;
        gap: 5px;
    }
    .icon {
        width: 25%;
    }
    .RightSection h4 {
        font-size: 13px;
    }
    .RightSection h1 {
        font-size: 40px;
        margin: 5px 0 0 0;
    }
    .RightSection .text p {
        font-size: 12px;
    }
    .gridItem .text p {
        font-size: 10px;
        text-align: left;
    }
    .btn {
        font-size: 11px;
        padding: 10px 18px;
    }
}
@media only screen and (max-width: 400px) {
    .RightSection h1 {
        font-size: 40px;
        margin: 5px 0 0 0;
    }
    .RightSection h4 {
        font-size: 13px;
    }
    .RightSection p {
        font-size: 13px;
    }
    .gridItem .text p {
        font-size: 13px;
        text-align: left;
    }
    .btn {
        font-size: 13px;
        padding: 10px 18px;
    }
}

@media only screen and (max-width: 320px) {
    .RightSection h1 {
        font-size: 35px;
        margin: 5px 0 0 0;
    }
    .RightSection h4 {
        font-size: 10px;
    }
    .RightSection p {
        font-size: 10px;
    }
    .gridItem .text p {
        font-size: 13px;
        text-align: left;
    }
    .btn {
        font-size: 11px;
        padding: 10px 18px;
    }
}

.aboutSeoButton {
    display: flex;
    gap: 0.8rem;
}

.aboutSeoButton a {
    width: fit-content;
}

@media (width< 992px) {
    .AboutSection {
        grid-template-columns: 1fr;
    }
}

@media (width<768px) {
    .modalDiv iframe {
        width: 350px !important;
        height: 300px;
    }

    .AboutVideo {
        height: 20rem;
    }

    .RightSection {
        padding: 40px 0 20px 20px;
        width: 86%;
    }

    .RightSection h2 {
        font-size: 36px;
    }

    .AboutPlayButton {
        position: absolute;
        top: 35%;
        cursor: pointer;
        left: 40%;
        font-size: 5em;
        color: #3195ffc7;
        transition: all 0.2s ease-in-out;
    }
}
